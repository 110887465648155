import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/catalyst/table";
import { useEffect, useState } from "react";
import DeleteModal from "../modals/deleteModal";
import Profile from "../modals/profile";
import dp from "../../assets/dp.png";
import deleteIcon from "../../assets/deleteIcon.png";
import editIcon from "../../assets/editIcon.png";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { usePagination } from "../PaginationProvider";

interface ProfilesProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
}

interface User {
  id: number;
  username: string;
  password: string;
  email: string;
  createdAt: string;
  updatedAt: string;
}

interface Department {
  id: number;
  dept_name: string;
  locationId: number;
  createdAt: string;
  updatedAt: string;
}

interface Position {
  id: number;
  position_name: string;
  createdAt: string;
  updatedAt: string;
}

interface Role {
  id: number;
  role: string;
  code_name: string;
  createdAt: string;
  updatedAt: string;
}

interface Profile {
  _id: number;
  emp_id: string;
  first_name: string;
  last_name: string;
  email: string;
  contact: string;
  profilePicture: string | null;
  userId: number;
  deptId: number;
  positionId: number;
  roleId: number;
  createdAt: string;
  updatedAt: string;
  User: User;
  department: Department;
  position: Position;
  role: Role;
  username:any;
}

const Profiles: React.FC<ProfilesProps> = ({
  isModalVisible,
  setModalVisible,
}) => {
  const { currentPage, setCurrentPage, setTotalPages, setTotalCount, count } = usePagination();
  const [content, setContent] = useState<string>("");
  const { t, i18n } = useTranslation();
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [deleteRequestModal, setDeleteRequestModal] = useState<
    boolean | string | number
  >(false);

  const fetchProfiles = async () => {
    try {
      const data = await fetchWithToken(`/users?page=${currentPage}`, {
        method: "GET",
      });
      setProfiles(data?.data);
      setCurrentPage(data.page);
      setTotalPages(data.total);
      setTotalCount(data?.limit)
    } catch (error) {
      console.error("Failed to fetch swap requests:", error);
    }
  };

  useEffect(() => {
    fetchProfiles();
  }, [currentPage, count]);
  return (
    <>
      <DeleteModal
        route="users"
               //content={content}

        visibilityState={deleteRequestModal}
        setState={setDeleteRequestModal}
        fetchAllCall={fetchProfiles}
      />
      <Profile
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        fetchProfiles={fetchProfiles}
      />
      <Table className="relative mt-5">
        <TableHead className="hidden lg:table-header-group">
          <TableRow className="bg-[#F7F8F7]">
            <TableHeader>{t("Employee Id")}</TableHeader>
            <TableHeader>{t("Profiles")}</TableHeader>
            <TableHeader>{t("Contact")}</TableHeader>
            <TableHeader>{t("User Name")}</TableHeader>
            <TableHeader>{t("Department")}</TableHeader>
            <TableHeader>{t("Position")}</TableHeader>
            <TableHeader>{t("Role")}</TableHeader>
            <TableHeader></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(profiles) &&
            profiles?.map((each) => (
              <TableRow
                key={each?._id}
                className="lg:table-row flex flex-col lg:flex-row mb-4 border lg:border-none"
              >
                <TableCell className="lg:hidden font-bold bg-gray-100">
                  {t("Employee Id")}
                </TableCell>
                <TableCell>{each?._id}</TableCell>

                <TableCell className="lg:hidden font-bold bg-gray-100">
                  {t("Profiles")}
                </TableCell>
                <TableCell>
                  <div className="flex items-center">
                    <img alt="profile" src={dp} className="w-[36px]" />
                    <div className="flex flex-col justify-center ml-1">
                      <div className="text-[16px] font-[600] mt-0">
                        {`${each?.first_name} ${each?.last_name}`}
                      </div>
                      <div className="text-[12px] -mt-2">{each?.email}</div>
                    </div>
                  </div>
                </TableCell>

                <TableCell className="lg:hidden font-bold bg-gray-100">
                  {t("Contact")}
                </TableCell>
                <TableCell>{each?.contact || "No Contact"}</TableCell>

                <TableCell className="lg:hidden font-bold bg-gray-100">
                  {t("User Name")}
                </TableCell>
                <TableCell>{each?.username}</TableCell>

                <TableCell className="lg:hidden font-bold bg-gray-100">
                  {t("Department")}
                </TableCell>
                <TableCell>
                  {each?.department?.dept_name
                    ? each?.department?.dept_name
                    : t("No Departmant")}
                </TableCell>

                <TableCell className="lg:hidden font-bold bg-gray-100">
                  {t("Position")}
                </TableCell>
                <TableCell>
                  {each?.position?.position_name
                    ? each?.position?.position_name
                    : t("No Position Name")}
                </TableCell>

                <TableCell className="lg:hidden font-bold bg-gray-100">
                  {t("Role")}
                </TableCell>
                <TableCell>
                  {each?.role?.role ? each?.role?.role : "No Role"}
                </TableCell>

                <TableCell className="flex justify-end lg:w-[120px]">
                  <div
                    onClick={() => {
                      setModalVisible(each?._id);
                    }}
                    className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                  >
                    <img alt="editIcon" src={editIcon} className="w-6 h-6" />
                  </div>
                  <div
                    onClick={() => {
                      setDeleteRequestModal(each?._id);
                      setContent(`${each?.first_name} ${each?.last_name}`);
                    }}
                    className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                  >
                    <img
                      alt="deleteIcon"
                      src={deleteIcon}
                      className="w-6 h-6"
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};

export default Profiles;
