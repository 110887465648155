import React, { useEffect, useState } from "react"; // Import necessary React hooks
import moment from "moment"; // Import moment.js to handle date and time formatting
import close from "../../assets/close.png"; // Import image assets for closing the chat
import add from "../../assets/add.png"; // Import image assets for adding new chat or group
import noProfile from "../../assets/noProfile.png"; // Import placeholder for user profile image
import SingleChat from "./singleChat"; // Import a component for displaying individual chat
import { useTranslation } from 'react-i18next'; // Import for handling multi-language translation
import fetchWithToken from "../../utils/api"; // Utility for making authenticated API requests

interface chatProps {
  showChats: boolean; // Whether to display the chat modal or not
  setShowChats: React.Dispatch<React.SetStateAction<boolean>>; // Function to change the visibility state of the chat
}

const Chats: React.FC<chatProps> = ({ showChats, setShowChats }) => {
  const [openChat, setOpenChat] = useState<boolean>(false); // State to manage whether a specific chat is open
  const [chats, setChats] = useState<any[]>([]); // State to store chat data
  const { t } = useTranslation(); // Translation hook to manage multiple languages

  // Function to fetch chats from the API
  const fetchChats = async () => {
    try {
      const data = await fetchWithToken("/notifications", { // Make an API request to fetch chat data
        method: "GET",
      });
      setChats(data); // Update the chats state with the fetched data
    } catch (error) {
      // Display error message in the current language if fetching fails
      console.error(t("Failed to fetch chats:"), error);
    }
  };

  // Use effect hook to fetch chats once when the component is mounted
  useEffect(() => {
    fetchChats();
  }, []); // Empty dependency array means this runs only once when the component is mounted

  // Function to format the time difference between the current time and the created timestamp of the chat
  const formatTimeDifference = (createdDateTime: string) => {
    const createdMoment = moment(createdDateTime); // Create a moment object from the chat creation date
    const now = moment(); // Get the current date and time
    const totalMinutes = now.diff(createdMoment, "minutes"); // Calculate the total difference in minutes
    const hours = Math.floor(totalMinutes / 60); // Calculate hours from the total minutes
    const minutes = totalMinutes % 60; // Calculate remaining minutes
    const days = Math.floor(hours / 24); // Calculate days from the total hours
    
    // Return formatted string based on the number of days, hours, or minutes
    if (days === 1) {
        return t("days_ago_arabic_one", { count: days });
    } else if (days === 2) {
        return t("days_ago_arabic_two", { count: days });
    } else if (days >= 3 && days <= 10) {
        return t("days_ago_arabic_few", { count: days });
    } else if (days >= 11) {
        return t("days_ago_arabic_many", { count: days });
    } else if (hours >= 1) {
        return t("hours_minutes_ago", { hours, minutes });
    } else if (minutes > 0) {
        return t("minutes_ago", { minutes });
    } else {
        return t("now");
    }
  };

  return (
    <>
      {showChats ? ( // Conditionally render chats if showChats is true
        <main className="w-full md:w-[28vw] h-[85vh] fixed top-[12vh] right-0 md:right-[1vw] z-40 bg-white rounded-lg overflow-y-scroll scrollbar-hidden">
          <SingleChat showChat={openChat} setShowChat={setOpenChat} /> {/* Render the SingleChat component */}
          <div className="relative mx-3">
            <div className="my-5 text-[#242222] text-2xl font-bold text-center relative">
              <div>{t("Chat")}</div> {/* Display translated text for "Chat" */}
              <img
                src={close}
                alt="X"
                onClick={() => setShowChats(false)} // Close the chat when clicking the close icon
                className="w-4 h-4 mt-3 absolute right-0 top-0"
              />
            </div>
            <div className="w-[35%] h-[10vh] mx-auto my-7 flex justify-between">
              {/* Button for adding a new chat */}
              <div className="w-[60px] h-full">
                <img
                  src={add}
                  alt="new chat"
                  className="w-[40px] h-[40px] rounded mx-auto bg-[#F8FAF8]"
                />
                <div className="text-xs font-semibold leading-[18px] my-1">
                  {t("New Chat")} {/* Display translated text for "New Chat" */}
                </div>
              </div>
              {/* Button for adding a new group */}
              <div className="w-[60px] h-full">
                <img
                  src={add}
                  alt="new group"
                  className="w-[40px] h-[40px] rounded mx-auto bg-[#F8FAF8]"
                />
                <div className="text-xs font-semibold leading-[18px] my-1">
                  {t("New Group")} {/* Display translated text for "New Group" */}
                </div>
              </div>
            </div>
            <div className="my-7">
              {/* Map through chats and render each chat */}
              {Array.isArray(chats) && chats.map((chat, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setOpenChat(true); // Open the specific chat when clicked
                  }}
                  className="w-full h-[50px] mb-4 relative inline-flex"
                >
                  <img
                    src={noProfile}
                    alt="Profile"
                    className="h-[50px] w-[50px] rounded-full"
                  />
                  <div className="w-full flex justify-between ml-3">
                    <div className="text-base font-semibold leading-6 text-[#101010]">
                      {chat.senderName} {/* Display sender's name */}
                    </div>
                    <div className="text-sm text-[#5d6561]">
                      {formatTimeDifference(chat.createdAt)} {/* Display formatted time difference */}
                    </div>
                  </div>
                  <div className="text-base font-normal absolute left-[62px] top-7 text-[#5D6561] leading-6">
                    {chat.message} {/* Display the message content */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      ) : null} {/* Only render if showChats is true */}
    </>
  );
};

export default Chats;
