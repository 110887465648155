import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import DeleteModal from "../modals/deleteModal";
import DepartmentModal from "../modals/departmentModal";
import editIcon from "../../assets/editIcon.png";
import deleteIcon from "../../assets/deleteIcon.png";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { usePagination } from "../PaginationProvider";

interface Location {
  id: number;
  name: string;
  addr_one: string;
  addr_two: string;
  city: string;
  state: string;
  country: string;
  postal_code: number;
  createdAt: string;
  updatedAt: string;
}

interface Department {
  _id: number;
  name: string;
  manager_id: number;
  createdAt: string;
  updatedAt: string;
  location_id: Location;
}

interface departmentsProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
}

const Departments: React.FC<departmentsProps> = ({
  isModalVisible,
  setModalVisible,
}) => {
  const { currentPage, setCurrentPage, setTotalPages, setTotalCount, count } = usePagination();
  const [content, setContent] = useState<string>("");
  const [departments, setDepartments] = useState<Department[]>([]);
  const { t, i18n } = useTranslation();

  const [deleteDepartmentModal, setDeleteDepartmentModal] = useState<
    boolean | number | string
  >(false);

  const fetchDepartments = async () => {
    try {
      const data = await fetchWithToken(`/departments?page=${currentPage}`, {
        method: "GET",
      });
      setDepartments(data?.data);
      setCurrentPage(data.page);
      setTotalPages(data.total);
      setTotalCount(data?.limit)
      
    } catch (error) {
      console.error("Failed to fetch departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, [currentPage, count]);

  return (
    <>
      <Table className="relative mt-5 w-full">
        <TableHead className="hidden lg:table-header-group w-full">
          <TableRow className="bg-[#F7F8F7] w-full">
            <TableHeader>{t("Department")}</TableHeader>
            <TableHeader>{t("Hospital")}</TableHeader>
            <TableHeader></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody className="w-full">
          {Array.isArray(departments) && departments?.map((department) => (
            <TableRow
              key={department._id}
              className="lg:table-row flex flex-col lg:flex-row mb-4 border lg:border-none w-full"
            >
              <TableCell className="lg:hidden font-bold bg-gray-100">{t("Department")}</TableCell>
              <TableCell>{department?.name}</TableCell>

              <TableCell className="lg:hidden font-bold bg-gray-100">{t("Hospital")}</TableCell>
              <TableCell >
                {department?.location_id?.name}
              </TableCell>

              <TableCell className="flex justify-end  w-full">
                <div
                  onClick={() => {
                    setModalVisible(department._id);
                  }}
                  className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                >
                  <img alt="editIcon" src={editIcon} className="w-6 h-6" />
                </div>
                <div
                  onClick={() => {
                    setDeleteDepartmentModal(department._id);
                    setContent(department.name);
                  }}
                  className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                >
                  <img alt="deleteIcon" src={deleteIcon} className="w-6 h-6" />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DepartmentModal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        fetchDepartments={fetchDepartments}
      />
      <DeleteModal
        route="departments"
        //content={content}
        visibilityState={deleteDepartmentModal}
        setState={setDeleteDepartmentModal}
        fetchAllCall={fetchDepartments}
      />
    </>
  );
};

export default Departments;
