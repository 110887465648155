import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-dropdown/style.css";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import SuccessToast from "./successToast";
import { setAdminStatus, setUser, setUserPermissions } from "../../utils/redux";
import { useDispatch } from "react-redux";

interface roleProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
  fetchRoles: () => void;
}

const RoleModal: any = ({
  isModalVisible,
  setModalVisible,
  fetchRoles,
}) => {
  const isAdd = isModalVisible === true;
  const { t } = useTranslation();
  const [permissionsData, setPermissionsData] = useState<any>({});
  const [flattenedPermissions, setFlattenedPermissions] = useState<string[]>([]);
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required(t("Required")),
      name: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      try {
        await fetchWithToken(isAdd ? "/roles" : `/roles/${isModalVisible}`, {
          method: isAdd ? "POST" : "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: values.name,
            code: values.code,
            permissions: flattenedPermissions,
          }),
        });
        setModalVisible(!isModalVisible);
        fetchRoles();
        SuccessToast(
          t("Accepted"),
          isAdd ? t("Role Added Successfully") : t("Role Updated Successfully"),
          t
        );
        userdata()
      } catch (error) {
        console.error("Error creating role:", error);
      }
    },
  });

  const getRoleDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/roles/${id}`, { method: "GET" });
      formik.setFieldValue("code", data.data.code);
      formik.setFieldValue("name", data.data.name);
      const newFlattenedPermissions = (Object.values(data.data.permissions).flat() as { name: string }[])
        .map((permission) => permission.name);
      setFlattenedPermissions(newFlattenedPermissions);
    } catch (error) {
      console.error("Failed to fetch role:", error);
    }
  };

  const getPermissionsData = async () => {
    try {
      const data = await fetchWithToken(`/permissions`, { method: "GET" });
      setPermissionsData(data);
    } catch (error) {
      console.error("Failed to fetch permissions:", error);
    }
  };

  useEffect(() => {
    getPermissionsData();
  }, []);

  useEffect(() => {
    formik.resetForm();
    if (typeof isModalVisible === "number" || typeof isModalVisible === "string") {
      getRoleDetails(isModalVisible);
    }
  }, [isModalVisible]);

  const handleParentCheckbox = (parent: string) => {
    setFlattenedPermissions((prev) => {
      const parentPermissions = permissionsData[parent] || [];
      if (parentPermissions.every((child: string) => prev.includes(child))) {
        // Remove all child permissions if parent is unchecked
        return prev.filter((permission) => !parentPermissions.includes(permission));
      } else {
        // Add all child permissions if parent is checked
        return Array.from(new Set([...prev, ...parentPermissions]));
      }
    });
  };

  const handleChildCheckbox = (parent: string, child: string) => {
    setFlattenedPermissions((prev: string[]) => {
      if (prev.includes(child)) {
        return prev.filter((permission) => permission !== child);
      } else {
        return [...prev, child];
      }
    });
  };

  const renderPermissions = () => {
    return Object.keys(permissionsData).map((parent) => (
      <div key={parent} className="mb-2">
        <label className="font-bold flex items-center">
          <input
            type="checkbox"
            checked={permissionsData[parent]?.every((child: string) =>
              flattenedPermissions.includes(child)
            )}
            onChange={() => handleParentCheckbox(parent)}
            className="mr-2"
          />
          {parent}
        </label>
        <div className="ml-4">
          {permissionsData[parent]?.map((child: string) => (
            <label key={child} className="flex items-center ml-2">
              <input
                type="checkbox"
                checked={flattenedPermissions.includes(child)}
                onChange={() => handleChildCheckbox(parent, child)}
                className="mr-2"
              />
              {child}
            </label>
          ))}
        </div>
      </div>
    ));
  };

  const userdata = async () => {
    try {
      const innerdata = await fetchWithToken("/users/me", {
        method: "GET",
      });
      
      dispatch(setUser(innerdata?.data))
      dispatch(setAdminStatus(innerdata?.data?.role?.code === "admin" ? true : false))
        const permdata = await fetchWithToken(`/roles/${innerdata?.data?.role_id}`, { method: "GET" });
        const newFlattenedPermissions = (Object.values(permdata.data.permissions).flat() as { name: string }[])
          .map((permission) => permission.name);
        dispatch(setUserPermissions(newFlattenedPermissions))
 
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  };


  

  return (
    isModalVisible && (
      <main
        onClick={() => setModalVisible(!isModalVisible)}
        className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center items-center"
      >
        <div className="container my-auto">
          <div
            onClick={(e) => e.stopPropagation()}
            className="
            w-full max-w-[385px] h-auto max-h-[600px]
            py-5 mx-auto capitalize bg-white
            rounded-lg flex flex-col items-center 
            overflow-y-auto"
            >
            <div className="text-center text-lg font-bold">{t("Role")}</div>
            <div className="text-sm text-[#101010]">
              <div className="font-bold">{t("Role")}</div>
              <input
                type="text"
                placeholder={t("RoleLabel")}
                name="name"
                required
                className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                style={{
                  borderColor: formik.touched.name && formik.errors.name ? "#E23121" : "#5D6561",
                }}
              />
              <div className="font-bold">{t("CodeName")}</div>
              <input
                type="text"
                placeholder={t("CodeNameL")}
                name="code"
                required
                className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.code}
                style={{
                  borderColor: formik.touched.code && formik.errors.code ? "#E23121" : "#5D6561",
                }}
              />
            </div>
            <div className="w-[350px]">
              <div>{renderPermissions()}</div>
              <button
                type="button"
                onClick={() => setModalVisible(!isModalVisible)}
                className="w-[168px] h-[40px] rounded-[8px] border border-[#00a843] text-[#00a843] hover:border-[#E23121] hover:text-[#E23121] text-[16px] font-[700] px-[24px] py-[8px]"
              >
                {t("Cancel")}
              </button>
              <button
                type="submit"
                onClick={(e: any) => formik.handleSubmit()}
                className="w-[168px] rounded-[8px] bg-[#56b77b] hover:bg-[#00A843] text-[#F8FAF8] p-2 text-[16px] mt-5 px-[24px] py-[8px] ml-[13px]"
              >
                {t("Confirm")}
              </button>
            </div>
          </div>
        </div>
      </main>
    )
  );
};

export default RoleModal;
