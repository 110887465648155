import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import dp from "../../assets/noProfile.svg";
import editIcon from "../../assets/editIcon.png";
import deleteIcon from "../../assets/deleteIcon.png";
import SwapRequest from "../modals/swapRequest";
import SwapMessage from "../modals/swapMessage";
import moment from "moment";
import DeleteModal from "../modals/deleteModal";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import SwapRequestSetting from "../modals/swapRequestSetting";
import SwapShift from "../modals/swapShift";

interface Profile {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  contact: string;
  birthdate: string | null;
  profilePicture: string | null;
  emp_id: number;
  userId: number;
  deptId: number;
  positionId: number;
  roleId: number;
  createdAt: string;
  updatedAt: string;
}

interface Schedule {
  id: number;
  date: string;
  profileId: number;
  shiftId: number;
  deptId: number;
  createdAt: string;
  updatedAt: string;
  profile: Profile | null;
}

interface SwapRequest {
  to_schedule: any;
  from_schedule: any;
  to_user: any;
  from_user: any;
  id: number;
  schedule_from: number;
  schedule_to: number;
  req_message: string;
  accepted: boolean;
  priority: number | null;
  notes: string | null;
  createdswap: boolean;
  status: string;
  createdAt: string;
  updatedAt: string;
  scheduleFrom: Schedule;
  scheduleTo: Schedule;
}


const SwapRequests: React.FC<any> = ({
  isModalVisible, isSettingModalVisible, setIsSettingModalVisible, setModalVisible, week, selectedUser
}) => {
  const [swapRequestsData, setSwapRequestsData] = useState<SwapRequest[]>([]);
  const [deleteRequestModal, setDeleteRequestModal] = useState<boolean | string | number>(false);
  const [isMsgTxt, setMsgTxt] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const { t } = useTranslation();

  const fetchSwapRequests = async () => {
    try {
      const data = await fetchWithToken("/swap-requests?status=pending", { method: "GET" });
      setSwapRequestsData(data?.data?.from_user?.length ? data?.data?.from_user : []);
    } catch (error) {
      console.error("Failed to fetch swap requests:", error);
    }
  };

  useEffect(() => {
    fetchSwapRequests();
  }, []);

  return (
    <>
      <SwapRequestSetting
        isModalVisible={isSettingModalVisible}
        setModalVisible={setIsSettingModalVisible}
        handleRefresh={fetchSwapRequests}
      />
      <SwapShift
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
      />
      {/* <SwapRequest
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        fetchSwapRequests={fetchSwapRequests}
        data={swapRequests.filter((request) => request.id === isModalVisible)[0]}
      /> */}
      <SwapMessage isMsgTxt={isMsgTxt} setMsgTxt={setMsgTxt} />
      <DeleteModal
        route="swap-requests"
                //content={content}

        visibilityState={deleteRequestModal}
        setState={setDeleteRequestModal}
        fetchAllCall={fetchSwapRequests}
      />
      <Table className="relative mt-5 w-full">
        {/* Table Head, hidden on smaller screens */}
        <TableHead className="hidden lg:table-header-group w-full">
          <TableRow className="bg-[#F7F8F7] w-full">
            <TableHeader className="!outline-none !border-b-0">
              <div className="flex items-center">
                {t("From")}
                <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0">
              <div className="flex items-center">
                {t("To")}
                <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0">
              <div className="flex items-center">
                {t("FromDate")}
                <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0">
              <div className="flex items-center">
                {t("ToDate")}
                <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0">
              <div className="flex items-center">
                {t("Status")}
                <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
              </div>
            </TableHeader>
          
            <TableHeader className="!outline-none !border-b-0"></TableHeader>
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody className="w-full">
          {Array.isArray(swapRequestsData) && swapRequestsData.map((swapRequest) => (
            <TableRow
              key={swapRequest?.id}
              className="lg:table-row flex flex-col lg:flex-row mb-4 border lg:border-none w-full"
            >
              {/* From Field */}
              <TableCell className="lg:hidden font-bold bg-gray-100">{t("From")}</TableCell>
              <TableCell >
                {swapRequest.from_user ? (
                  <div className="flex items-center">
                    <img
                      alt="profile"
                      src={swapRequest.from_user?.profile_picture || dp}
                      className="w-[36px]"
                      width={40}
                      height={40}
                    />
                    <div className="ml-3">
                      <div className="text-[16px] font-[600]">
                        {`${swapRequest?.from_user?.first_name} ${swapRequest?.from_user?.last_name}`}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>{t("No profile")}</div>
                )}
              </TableCell>
              {/* To Field */}
              <TableCell className="lg:hidden font-bold bg-gray-100">To</TableCell>
              <TableCell >
                {swapRequest.to_user ? (
                  <div className="flex items-center">
                    <img
                      alt="profile"
                      src={swapRequest?.to_user?.profilePicture || dp}
                      className="w-[36px]"
                      width={40}
                      height={40}
                    />
                    <div className="ml-3">
                      <div className="text-[16px] font-[600]">
                        {`${swapRequest?.to_user?.first_name} ${swapRequest?.to_user?.last_name}`}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>{t("No profile")}</div>
                )}
              </TableCell>
              {/* From Date */}
              <TableCell className="lg:hidden font-bold bg-gray-100">{t("FromDate")}</TableCell>
              <TableCell >
                {moment(swapRequest.from_schedule.date).format("MMMM Do YYYY")}
                <div>
                  {moment(swapRequest.from_schedule.date, "HH:mm:ss").format("hh:mm A")}
                </div>
              </TableCell>
              {/* To Date */}
              <TableCell className="lg:hidden font-bold bg-gray-100">{t("ToDate")}</TableCell>
              <TableCell >
                {moment(swapRequest?.to_schedule?.date).format("MMMM Do YYYY")}
                <div>
                  {moment(swapRequest?.to_schedule?.date, "HH:mm:ss").format("hh:mm A")}
                </div>
              </TableCell>
              {/* Status */}
              <TableCell className="lg:hidden font-bold bg-gray-100">{t("Status")}</TableCell>
              <TableCell >
                <div>{swapRequest?.status}</div>
              </TableCell>
              {/* Actions (Edit/Delete) */}
              <TableCell className="flex justify-end w-full">
                <div
                  onClick={() => setModalVisible(swapRequest.id)}
                  className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                >
                  <img alt="editIcon" src={editIcon} className="w-6 h-6" />
                </div>
                <div
                  onClick={() => {
                    setDeleteRequestModal(swapRequest.id);
                    setContent("Are you sure you want to delete this request?");
                  }}
                  className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                >
                  <img alt="deleteIcon" src={deleteIcon} className="w-6 h-6" />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

    </>
  );
};

export default SwapRequests;
