import React, { useState, useEffect } from "react";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import Select from "react-select";

interface AddNoteModalProps {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
  approved?: boolean | undefined;
  handleRefresh?: any;
}

const AddNoteModal: React.FC<AddNoteModalProps> = ({
  isModalVisible,
  setModalVisible,
  data,
  approved,
  handleRefresh,
}) => {
  const { t } = useTranslation();
  const [note, setNote] = useState("");
  const [buttonText, setButtonText] = useState(t("Title11"));
  const [errorMsg, setErrorMsg] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    if (isModalVisible) {
      setButtonText(t("Title11"));
      setNote("");
      setAnimate(false);
      setSelectedProfile(null);
    }
  }, [isModalVisible]);

  const handleAccept = async () => {
    if (!note) {
      setErrorMsg(!note);
      return;
    }

    setErrorMsg(false);

    try {
      const response = await fetchWithToken(`/swap-requests/approve`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          swap_request_id: data?.id,
          approved,
          note,
        }),
      });

      if (response && handleRefresh) {
        handleRefresh();
      }

      setModalVisible(false);
      SuccessToast(
        t("Accepted"),
        t(`You ${approved ? "accepted" : "declined"} request from ${data?.from_user?.first_name} ${data?.from_user?.last_name}`),
        t
      );
    } catch (error) {
      setModalVisible(false);
    }
  };

  return (
    <>
      {isModalVisible && (
        <main
          className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center align-middle z-[1]"
          onClick={() => setModalVisible(false)}
        >
          <div
            className="py-2 xl:w-[30%] w-[90%] h-[27%] z-[9999] overflow-auto m-auto capitalize bg-[#FFF] rounded-[8px] flex flex-col items-center scrollbar-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="py-3 text-center text-lg font-bold">
              {t("Title11")}
            </div>
            <textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder={t("Message3")}
              className="w-[90%] h-[40%] p-2 border rounded"
            />
            {errorMsg && (
              <div className="ml-6 mb-3 text-red-500">
                {t("writeMessage")}
              </div>
            )}

            <div className="flex justify-end w-[90%] mt-3">
              <button
                onClick={() => setModalVisible(false)}
                className="px-4 py-2 border border-green-500 text-green-500 mr-2 rounded"
              >
                {t("Cancel")}
              </button>
              <button
                onClick={handleAccept}
                className={`w-[174px] h-[40px] rounded text-white text-lg px-6 py-2 mr-2 ${animate
                  ? "animate-success"
                  : "bg-green-600 hover:bg-green-700"
                  }`}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default AddNoteModal;
