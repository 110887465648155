import { ToastOptions, toast } from "react-toastify";
import successToastIcon from "../../assets/successToast.svg"; // Use ES6 import for assets

const SuccessToast = (
  message: string, 
  item: string | undefined, 
  t: (key: string) => string
) => {
  const toastProps: ToastOptions = {
    position: "bottom-right",
    className: "foo-bar",
    icon: (
      <img
        alt="successToast"
        src={successToastIcon}
        className="w-[40px] min-w-[40px] max-w-[40px]"
      />
    ),
  };

  toast.success(
    <div className="msg-container ml-5">
      <p className="msg-title font-[700] text-[18px] text-[black]">
        {message} {t("successfully")}
      </p>
      {item && (
        <p className="msg-description text-[12px] text-[#5d6561] capitalize">
          {item}
        </p>
      )}
    </div>,
    toastProps
  );
};

export default SuccessToast;
