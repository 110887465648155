import React, { useEffect, useState } from "react";
import moment from "moment";
import calender from "../../assets/calender.png";
import clock from "../../assets/clock.png";
import stethoscope from "../../assets/stethoscope.png";
import locationImg from "../../assets/location.png";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import SwapShift from "./swapShift";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { useSelector } from "react-redux";

interface Location {
  id: number;
  name: string;
  address: string;
  createdAt: string;
  updatedAt: string;
  hospital_name: string;
}
interface Department {
  id: number;
  dept_name: string;
  location: Location;
  updatedAt: string;
}

interface Profile {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  contact: string;
  birthdate: string;
  profilePicture: string;
  emp_id: number;
  userId: number;
  deptId: number;
  positionId: number;
  roleId: number;
  createdAt: string;
  updatedAt: string;
}

interface ShiftDetails {
  id: number;
  start_time: string;
  end_time: string;
  shift_type: string | null;
  createdAt: string;
  updatedAt: string;
}

interface Schedule {
  id: number;
  date: string;
  profileId: number;
  shiftId: number;
  deptId: number;
  createdAt: string;
  updatedAt: string;
  department: Department;
  profile: Profile;
  shift: ShiftDetails;
}

interface ProfileProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
}

const ShiftDetails: any = ({
  isModalVisible,
  setModalVisible,
}) => {
  const UserPermissions = useSelector((state: any) => state?.user?.permissions);
  const hasCreateSwap = Array.isArray(UserPermissions) && UserPermissions.includes("create swap_requests");
  const userDepartment = useSelector((state: any) => state.user.user?.department?.name);
  const [schedule, setSchedule] = useState<Schedule>();
  const { t, i18n } = useTranslation();
  const [openSwapRequest, setOpenSwapRequest] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const scheduleDate = moment(schedule?.date);
  const today = moment();


  const fetchSchedule = async () => {
    setLoading(true);
    try {
      const data = await fetchWithToken(`/schedules/${isModalVisible}`, {
        method: "GET",
      });
      setSchedule(data?.data);
      setLoading(false);
    } catch (error) {
      console.error(t("Failed to fetch schedule:"), error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (typeof isModalVisible !== "boolean") fetchSchedule();
  }, [isModalVisible]);

  const handleOpenNewSwapRequest = () => {
    setOpenSwapRequest(true);
  };

  const handleCloseNewSwapRequest = () => {
    setOpenSwapRequest(false);
  };

  const handleSwapRequest = () => {

    handleOpenNewSwapRequest();
    // if (scheduleDate.isBefore(today, "day")) {
    //   FailToast("This Schdule Is Over");
    // } else {
    // }
  };

  return (
    isModalVisible && (
      <main
        className="absolute top-0 bottom-0 left-0 right-0 flex justify-center align-middle z-[1]"
        onClick={() => setModalVisible(!isModalVisible)}
      >
        <SwapShift
          isModalVisible={openSwapRequest}
          setModalVisible={handleCloseNewSwapRequest}
          data={schedule}
        />
        <div
          className="py-5 w-[270px] h-[280px] overflow-auto m-auto capitalize bg-[#FFF] rounded-[8px] flex flex-col items-center scrollbar-hidden"
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="py-1 text-center text-base font-bold">
            {t("ShiftDetails")}
          </div>
          {loading ? (
            <div className="mt-[50px] w-[50px] h-[50px] border-2 border-t-transparent border-[#9ad5a4] rounded-full animate-spin"></div>
          ) : (
            <>
              <div className="text-sm mt-2 text-[#101010] w-full">
                <div className="px-1 mx-[14px] text-base font-semibold text-[#161918]">
                  {schedule?.shift?.shift_type}
                </div>
                <div className="px-1 flex justify-between mb-2 mx-[14px]">
                  <div className="w-[170px] flex flex-col justify-between">
                    <div className="text-[12px] flex items-center w-full">
                      <img
                        alt="calender"
                        src={calender}
                        className="w-[13px]"
                      />
                      <div className="text-[16px] ml-3">
                        {moment(schedule?.date).format("ddd, DD MMM YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-1 flex justify-between mx-[14px]">
                  <div className="w-[170px] flex flex-col justify-between">
                    <div className="text-[12px] flex items-center w-full">
                      <img alt="clock" src={clock} className="w-[13px]" />
                      <div className="text-[16px] ml-3">
                        {`${moment(
                          schedule?.shift?.start_time,
                          "HH:mm:ss"
                        ).format("h:mm A")} - ${moment(
                          schedule?.shift?.end_time,
                          "HH:mm:ss"
                        ).format("h:mm A")}`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-1 m-3 mx-[14px]">
                  <div className="flex">
                    <img
                      src={stethoscope}
                      alt=""
                      className="w-[13px] h-[13px] my-auto"
                    />
                    <div className="text-base text-[#333937] ml-3">
                      {userDepartment || t("No Department")}
                    </div>
                  </div>
                  {/* <div className="flex">
                    <img
                      src={locationImg}
                      alt=""
                      className="w-[13px] h-[13px] my-auto"
                    />
                    <div className="text-base text-[#333937] ml-3">
                      {schedule?.department?.location?.hospital_name || t("No Location")}
                    </div>
                  </div> */}
                </div>
              </div>
              {
                hasCreateSwap && (
                  <>
                    {
                      !scheduleDate.isBefore(today, "day") && (
                        <div className="w-[80px]">
                          <button
                            onClick={handleSwapRequest}
                            className="rounded-[8px] bg-[#00A843] hover:bg-[#389365] text-[#F8FAF8] p-2 text-[16px] mt-5 px-[24px] py-[8px] mx-auto"
                          >
                            {t("Swap")}
                          </button>
                        </div>
                      )
                    }
                  </>
                )
              }


            </>
          )}
        </div>
      </main>
    )
  );
};

export default ShiftDetails;
