import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ children }) => {
  const token = Cookies.get("session");

  if (!token) {
    // Redirect to the login page if no token
    return <Navigate to="/" />;
  }

  // Render the children (the protected page) if token exists
  return children;
};

export default ProtectedRoute;
