import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";

interface ShiftProps {
  
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
  fetchShifts: () => void;

}

const Shift: any = ({
  
  isModalVisible,
  setModalVisible,
  fetchShifts,
  
}) => {
  const isAdd = isModalVisible === true;
  const { t, i18n } = useTranslation();
  const formik = useFormik({
    initialValues: {
      start_time: "",
      end_time: "",
      shift_type: "",
    },
    validationSchema: Yup?.object({
start_time: Yup?.string()?.required(t("Required")),
      end_time: Yup?.string()?.required(t("Required")),
      shift_type: Yup?.string()?.required(t("Required")),
    }),
    onSubmit: async (values) => {
      try {        
        await fetchWithToken(isAdd ? "/shifts" : `/shifts/${isModalVisible}`, {
          method: isAdd ? "POST" : "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            start_time: moment(values?.start_time, "HH:mm").format("HH:mm:ss"),
            end_time: moment(values?.end_time, "HH:mm").format("HH:mm:ss"),
            shift_type: values?.shift_type,
          }),
        });
        setModalVisible(!isModalVisible);
        fetchShifts();
      } catch (error) {
        console.error("Error creating shift:", error);
      }
    },
  });

  const getShiftDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/shifts/${id}`, {
        method: "GET",
      });
      formik?.setFieldValue("start_time", data.data?.start_time);
      formik?.setFieldValue("end_time", data.data?.end_time);
      formik?.setFieldValue("shift_type", data.data?.shift_type);

    } catch (error) {
      console.error(t("Failed to fetch shifts:"), error);
    }
  };

  useEffect(() => {
    formik?.resetForm();
    if (
      typeof isModalVisible === "number" ||
      typeof isModalVisible === "string"
    )
      getShiftDetails(isModalVisible);
  }, [isModalVisible]);

  return (
  
    isModalVisible && (
      <main
      className="fixed top-0 bottom-0 left-0 right-0 
      bg-black bg-opacity-30 flex justify-center items-center z-[1]"        
    
      onClick={() => setModalVisible(!isModalVisible)}
      >
        <div
          className="py-5 
          w-full max-w-[385px] h-auto max-h-[70%] 
          overflow-auto m-auto capitalize 
          bg-white rounded-lg flex flex-col items-center scrollbar-hidden"

                    
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="text-center text-lg font-bold">
{!isAdd ? t("Edit") : t("Add")}
          </div>
          <div className="text-sm text-[#101010] w-full px-5 ">
            <div className="font-bold">{t("StartTime")}</div>
            <input
              type="time"
              placeholder={t("StartTimeL")}
              name="start_time"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="start_time"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.start_time}
              style={{
                borderColor:
                  formik?.touched?.start_time && formik?.errors?.start_time
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.start_time && formik?.errors?.start_time && (
                <div>{formik?.errors?.start_time}</div>
              )}
            </div>
            <div className="font-bold">{t("EndTime")}</div>
            <input
              type="time"
              placeholder={t("EndTimeL")}
              name="end_time"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="end_time"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.end_time}
              style={{
                borderColor:
                  formik?.touched?.end_time && formik?.errors?.end_time
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.end_time && formik?.errors?.end_time && (
                <div>{formik?.errors?.end_time}</div>
              )}
            </div>
            <div className="font-bold">{t("ShiftType")}</div>
            <input
              type="text"
              placeholder={t("ShiftType")}
              name="shift_type"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="type"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.shift_type}
              style={{
                borderColor:
                  formik?.touched?.shift_type && formik?.errors?.shift_type
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.shift_type && formik?.errors?.shift_type && (
                <div>{formik?.errors?.shift_type}</div>
              )}
              </div>
          </div>
          <div className="w-[350px]">
            <button
              type="button"
              onClick={() => setModalVisible(false)}
              className="w-[168px] h-[40px] rounded-[8px] border border-[#00a843] text-[#00a843] hover:border-[#E23121] hover:text-[#E23121] text-[16px] font-[700] px-[24px] py-[8px]"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={(e: any) => {
                formik.handleSubmit();
              }}
              type="submit"
              className="w-[168px] rounded-[8px] bg-[#56b77b] hover:bg-[#00A843] text-[#F8FAF8] p-2 text-[16px] mt-5 px-[24px] py-[8px] ml-[13px]"
            >
              {t("Submit")}
            </button>
          </div>
        </div>
      </main>
    )
  );
};

export default Shift;