import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  permissions: {},
  isAdmin: false,
  firstSchedule: {}
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setUserPermissions: (state, action: PayloadAction<any>) => {
      state.permissions = action.payload;
    },
    setAdminStatus: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    setFirstSchedule: (state, action: PayloadAction<boolean>) => {
      state.firstSchedule = action.payload;
    },
  },
});

export const { setUser, setUserPermissions, setAdminStatus, setFirstSchedule } = userSlice.actions;

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
  },
});

export default store;
