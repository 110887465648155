import React, { useState } from "react";
import close from "../../assets/close.png";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
interface LogoutProps {
  isLogout: boolean;
  setIsLogout: React.Dispatch<React.SetStateAction<boolean>>;
}

const Logout: any = ({ isLogout, setIsLogout }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    isLogout && (
      <main
        onClick={() => setIsLogout(false)}
        className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center align-middle z-[1]"
      >
        <div className="container my-auto">
          <div className="py-5 w-[385px] h-[192px] rounded-lg p-4 bg-[#FFFFFF] overflow-auto m-auto scrollbar-hidden">
            <div
              className="w-[353px] h-[24px]"
              onClick={(e) => e?.stopPropagation()}
            >
              <div className="text-lg leading-6 font-semibold text-center">
               {t("Login")}
              </div>
              <div
                className="float-right w-[24px]"
                onClick={() => setIsLogout(false)}
              >
                <img src={close} alt="close" className="w-[13px] p-[13px]" />
              </div>
            </div>
            <div className="w-[353px]">
              <div className="w-[245px] h-[48px] mx-auto text-center text-[#101010] text-lg leading-6 font-normal mt-6">
              {t("Message")}
              </div>
              <button
                type="button"
                onClick={() => {
                  setIsLogout(false);
                }}
                className={`w-[168px] h-[40px] rounded-[8px] border border-[#00A843] text-[#389365] hover:border-[#1F7549] hover:text-[#1F7549]
                 text-[16px] font-[700] px-[24px] py-[8px]`}
              >
               {t("Cancel")}
              </button>
              <button
                onClick={async () => {
                  Cookies?.remove("session");
                  navigate("/");
                }}
                className={`w-[168px] rounded-[8px] bg-[#E23121] text-white p-2 text-[16px] mt-5 px-[24px] py-[8px] ml-[13px]`}
              >
                 {t("Logout")}
              </button>
            </div>
          </div>
        </div>
      </main>
    )
  );
};

export default Logout;
