import React, { memo, useState } from "react";
import ScheduleTable from "../../components/scheduleTable";
import Requests from "../../components/requests";



const Swap = () => {
  const [refresh,setRefresh] = useState(false);
  const handleRefresh = () =>{
    setRefresh((prev)=>!prev)
  }
  return(
  <div className="w-full h-full flex flex-1 gap-[24px] xl:overflow-hidden overflow-scroll xl:flex-row flex-col">
    <div className="flex flex-col min-w-[50%] max-w-full w-full">
      <ScheduleTable refresh={refresh} handleRefresh={handleRefresh}/>
    </div>
    <Requests refresh={refresh} handleRefresh={handleRefresh}/>
  </div>
)};

export default memo(Swap);
