import React, { useEffect, useState } from "react";
import moment from "moment";
import close from "../../assets/close.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";

interface notificationProps {
  showNotification: boolean;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

const Notifications: any = ({
  showNotification,
  setShowNotification,
}) => {
  const [notifications, setNotifications] = useState([]);
  const { t, i18n } = useTranslation();
  const fetchNotifications = async () => {
    try {
      const data = await fetchWithToken("/notifications", {
        method: "GET",
      });
      setNotifications(data?.data);
    } catch (error) {
      //console.error("Failed to fetch notifications:", error);
      console.error(t("Failed to fetch notifications:"), error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const clearAll = async () => {
    await fetchWithToken(`/notifications/mark-all-as-read`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });
    fetchNotifications()
  };

  const formatTimeDifference = (createdDateTime: string) => {
    const createdMoment = moment(createdDateTime);
    const now = moment();
    //return now.diff(createdMoment, "minutes") + " " + t("min ago")
    const totalMinutes = now.diff(createdMoment, "minutes");
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const days = Math.floor(hours / 24);
  
    if (days === 1) {
        return t("days_ago_arabic_one", { count: days }); // For 1 day
    } else if (days === 2) {
        return t("days_ago_arabic_two", { count: days }); // For 2 days
    } else if (days >= 3 && days <= 10) {
        return t("days_ago_arabic_few", { count: days }); // For 3 to 10 days
    } else if (days >= 11) {
        return t("days_ago_arabic_many", { count: days }); // For 11 and above
    } else if (hours >= 1) {
        return t("hours_minutes_ago", { hours, minutes });
    } else if (minutes > 0) {
        return t("minutes_ago", { minutes });
    } else {
        return t("now");
    }
  };

  return (
    showNotification && (
      <main className="w-full md:w-[28vw] h-[85vh] fixed top-[12vh] right-0 md:right-[1vw] z-40 bg-white rounded-lg overflow-y-scroll scrollbar-hidden">
        <div className="relative mx-3">
          <div className="my-5 text-[#242222] text-2xl font-bold text-center relative">
            <div>{t("Notifications")}</div>
            <img
              src={close}
              alt="X"
              onClick={() => setShowNotification(false)}
              className="w-4 h-4 mt-3 absolute right-0 top-0 cursor-pointer"
            />
          </div>
          <button
            onClick={clearAll}
            className="text-[#424A46] text-sm font-normal underline underline-offset-2 absolute right-0 hover:text-[#101010]"
          >
            {t("Readnotifications")}
          </button>
          <div className="mt-20">

            {Array.isArray(notifications) && notifications?.map((notification: any, index) => {
              return (
                <div key={index} className="m-2 bg-[#F7F8F7] rounded relative">
                  <div className="text-base text-[#101010] px-3 py-2 pb-8">
                    {notification?.message} {notification?.read === false && <div className="text-[green]">{t("new")}</div>}
                  </div>
                  <div className="text-[#5d6561] text-sm absolute bottom-2 right-3">
                    {formatTimeDifference(notification?.createdAt)}
                  </div>
                </div>
              );
            })}
   {/* the new on i tired it but dnw if i will keep it or no!
  Array.isArray(notifications) && notifications?.map((notification: any, index) => {
    return (
      <div key={index} className="m-2 bg-[#F7F8F7] rounded relative">
        <div className="text-base text-[#101010] px-3 py-2 pb-8">
          {notification?.message.includes("swap request")
            ? t("You have created a new swap request.")
            : notification?.message}
          {notification?.read === false && <div className="text-[green]">{t("new")}</div>}
        </div>
        <div className="text-[#5d6561] text-sm absolute bottom-2 right-3">
          {formatTimeDifference(notification?.createdAt)}
        </div>
      </div>
    );
  })
}*/}
          </div>
        </div>
      </main>
    )
  );
};

export default Notifications;
