import React, { memo, useEffect, useState } from "react";
import { WeekPicker } from "../weekpicker";
import moment from "moment";
import "./style.css";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import fetchWithToken from "../../utils/api";
import ShiftDetails from "../modals/shiftDetails";
interface TimeSlot {
  time: string;
}

const EveryoneTable = () => {
  const [times, setTimes] = useState<TimeSlot[]>([]);
  const [shiftDetailsModal, setShiftDetailsModal] = useState<
    boolean | string | number
  >(false);
  // const t9 = useTranslations("SecondPage");
  // const t6 = useTranslations('Firstpage');
  const { t, i18n } = useTranslation();
  const [schedule, setSchedule] = useState<any>();
  const [week, setWeek] = useState<any>();
  const firstDay = moment(week?.firstDay);
  const lastDay = moment(week?.lastDay);
  const daysOfWeek = [
    t("Monday"),
    t("Tuesday"),
    t("Wednesday"),
    t("Thursday"),
    t("Friday"),
    t("Saturday"),
    t("Sunday"),
  ];

  const weekDates = daysOfWeek.map((_, index) =>
    firstDay.clone().add(index, "days")
  );

  let displayMonth;
  if (firstDay.isSame(lastDay, "month")) {
    displayMonth = firstDay.format("MMMM YYYY");
  } else if (firstDay.isSame(lastDay, "year")) {
    displayMonth = `${firstDay.format("MMMM")} - ${lastDay.format(
      "MMMM YYYY"
    )}`;
  } else {
    displayMonth = `${firstDay.format("MMMM YYYY")} - ${lastDay.format(
      "MMMM YYYY"
    )}`;
  }

  const fetchSchedule = async (dateFrom: string, dateTo: string) => {
    const formattedDateFrom = moment(dateFrom).format("YYYY-MM-DD");
    const formattedDateTo = moment(dateTo).format("YYYY-MM-DD");

    try {
      const data = await fetchWithToken(
        `/schedules?startDate=${formattedDateFrom}&endDate=${formattedDateTo}`,
        {
          method: "GET",
        }
      );
      setSchedule(data?.data || []);
    } catch (error) {
      //console.error("Failed to fetch locations:", error);
      console.error(t("Failed to fetch locations:"), error);
    }
  };

  useEffect(() => {
    if (!week?.firstDay || !week?.lastDay) return;
    fetchSchedule(
      moment(week?.firstDay).toISOString(),
      moment(week?.lastDay).toISOString()
    );
  }, [week]);

  useEffect(() => {
    const startTime = moment().startOf("day").add(0, "hours");

    const timeArray: TimeSlot[] = Array.from({ length: 24 }, (_, index) => ({
      time: startTime.clone().add(index, "hours").format("h:mm A"),
    }));

    setTimes(timeArray);
  }, []);

  return (
    <div className="h-full w-full">
         <ShiftDetails
          isModalVisible={shiftDetailsModal}
          setModalVisible={setShiftDetailsModal}
        />
      <div className="flex flex-col sm:flex-row items-center my-2 h-[40px] xl:mb-1 mb-[70px] ">
        <div className="text-[24px] font-[700] mb-2 sm:mb-0 sm:mr-4 md:mr-[10%] w-full sm:w-auto md:w-[300px]">
          {displayMonth}
        </div>
        <WeekPicker onChange={(e: any) => setWeek(e)} />
      </div>
      <div className="overflow-y-scroll h-[90%]">
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader
                style={{
                  border: 0,
                  padding: 0,
                }}
                className={"xl:w-[100px] w-[10px]"}
              >
                <div className="flex justify-center">
                  {/* <Image
                    alt="calenderDark"
                    src={calenderDark}
                    className="xl:w-[20px] w-[14px]"
                  /> */}
                </div>
              </TableHeader>
              {weekDates.map((date, index) => (
                <TableHeader
                  key={daysOfWeek[index] + index}
                  className="w-[40px] xl:w-[120px]"
                  style={{
                    border: 0,
                    padding: 5,
                  }}
                >
                  <div className="w-full h-[76px] flex flex-col items-center justify-center bg-[#f7f8f7] rounded-4px">
                    <div className="xl:text-[16px] text-[8px] font-[600]">
                      {daysOfWeek[index]}
                    </div>
                    <div className="xl:text-[24px] text-[14px] font-[500] mt-1">
                      {date.format("DD")}
                    </div>
                  </div>
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {times.map((each: any, index) => (
              <TableRow key={each?.time}>
                <TableCell
                  style={{ border: 0, padding: 0 }}
                  className={
                    "bg-[#f7f8f7] font-[600] xl:text-[12px] text-[8px]"
                  }
                >
                  <div className="h-[50px] w-full flex items-center justify-center">
                    {each?.time}
                  </div>
                </TableCell>
                {weekDates.map((val, index2) => (
                  <TableCell
                    key={index2}
                    style={{
                      border: 0,
                      padding: 0,
                    }}
                    className="relative"
                  >
                    {schedule?.filter((schdl) =>
                      moment(schdl?.date)?.isSame(moment(val), "day")
                    )?.length ? (
                      schedule
                        ?.filter((schdl) =>
                          moment(schdl?.date)?.isSame(moment(val), "day")
                        )
                        ?.map((schdl) => {
                          const isStartTime = moment(schdl?.shift?.start_time, "HH:mm:ss")?.isSame(moment(each?.time, "h:mm A"));
                          const isEndTime = moment(schdl?.shift?.end_time, "HH:mm:ss")?.isSame(moment(each?.time, "h:mm A"));
                          const isInRange = moment(each?.time, "h:mm A").isBetween(moment(schdl?.shift?.start_time, "HH:mm:ss"), moment(schdl?.shift?.end_time, "HH:mm:ss").add(1, "hour"), undefined, "[)");

                          return (
                            (isStartTime || isEndTime || isInRange) && (
                              <div
                                key={schdl?._id}
                                className="absolute inset-0 flex items-center justify-center"
                              >
                               
                                <div
                                  className={`w-full min-h-[70px] flex flex-col items-center justify-between relative mx-1 ${isStartTime ? "rounded-t-[8px]" : ""} ${isEndTime ? "rounded-b-[8px]" : ""} ${isInRange ? "bg-[#c0b0ff]" : ""}`}
                                >
                                  
                                  {moment(schdl?.shift?.start_time, "HH:mm:ss")
                                    .add(
                                      moment(
                                        schdl?.shift?.end_time,
                                        "HH:mm:ss"
                                      ).diff(
                                        moment(
                                          schdl?.shift?.start_time,
                                          "HH:mm:ss"
                                        )
                                      ) / 2,
                                      "milliseconds"
                                    )
                                    ?.isSame(moment(each?.time, "h:mm A")) && (
                                      <div className="bg-[#A5EEE1] w-full overflow-hidden xl:flex pt-1 pb-5 text-center border border-r-2 rounded-[8px]  items-center justify-center absolute top-0 left-0 right-0 bottom-0">
                                        <div className="flex flex-col xl:w-[160px] text-wrap xl:text-nowrap absolute left-[-7px]">
                                          <div className="hidden md:block">
                                            <div className="text-[16px] font-[600] mt-[8px] xl:text-nowrap text-wrap">
                                              {`${moment(
                                                schdl?.shift?.start_time,
                                                "HH:mm:ss"
                                              ).format("h A")} - ${moment(
                                                schdl?.shift?.end_time,
                                                "HH:mm:ss"
                                              ).format("h A")}`}
                                            </div>
                                          </div>
                                          <div className="flex flex-col justify-center align-middle ml-3 pb-1 sm:block md:hidden">
                                            <div className="text-[10px] font-[600]">
                                              {`${moment(
                                                schdl?.shift?.start_time,
                                                "HH:mm:ss"
                                              ).format("h A")} `}
                                            </div>
                                            <div className="text-[10px] font-[600]">
                                              {` ${moment(
                                                schdl?.shift?.end_time,
                                                "HH:mm:ss"
                                              ).format("h A")}`}
                                            </div>
                                          </div>
                                          <div className="pb-[12px] z-0 xl:text-[12px] text-[8px] hidden xl:block text-wrap xl:text-nowrap">
                                            {schdl?.shift?.shift_type}
                                          </div>
                                        </div>
                                        <div className="absolute right-0 top-0 mt-0 ml-0 hidden xl:block ">
                                          <button
                                            onClick={() => setShiftDetailsModal(schdl?._id ?? false)}

                                            className="w-[40px] h-[30px] text-[14px] rounded-lg bg-[#00A843] hover:bg-[#389365] text-white p-2 text-sm  flex items-center justify-center"
                                          >
                                            {t("view")}
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            )
                          );
                        })
                    ) : (
                      <div
                        className="flex items-center justify-center h-full w-full"
                        key={index}
                      >
                        <div
                          className={`w-full min-h-[70px] flex flex-col items-center justify-center relative mx-1 ${index === 0 ? "rounded-t-[8px]" : ""
                            } ${index === times?.length - 1 ? "rounded-b-[8px]" : ""
                            } bg-[#a5eebe]`}
                        >
                          {index === 0 && (
                            <div className="xl:text-[16px] text-[8px] font-[600]">
                              {t("DayOff")}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default memo(EveryoneTable);
