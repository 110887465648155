import React, { useState } from "react";
import moment from "moment";

import reqMsg from "../../assets/reqMsg.png";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";

interface SwapReq {
  id: number;
  coworker: string;
  fromdate: string;
  todate: string;
  currentstarttime: string;
  currentendtime: string;
  desiredstarttime: string;
  desiredendtime: string;
  department: number;
  location: number;
  req_message: string;
}

interface SwapRequests {
  id: number;
  schedule_from: string;
  startfrom: string;
  created_datetime: string;
  endfrom: string;
  shifttypefrom: string;
  profile_from: number;
  first_name_from: string;
  last_name_from: string;
  dept_from: string;
  position_from: string;
  code_name_from: string;
  location_from: string;
  schedule_to: string;
  startto: string;
  endto: string;
  shifttypeto: string;
  profile_to: number;
  first_name_to: string;
  last_name_to: string;
  dept_to: string;
  position_to: string;
  code_name_to: string;
  location_to: string;
  req_message: string;
}

interface SwapRequestProps {
  data?: SwapRequests | undefined;
  isModalVisible?: boolean | string | number;
  setModalVisible?: any;
  handleRefresh?: () => void;
  fetchSwapRequests?: any;
}

interface DynamicItem {
  id: number;
  role: string;
  isChecked: boolean;
}

interface StateMachineItem {
  type: string;
  is_required: boolean;
  associated_id?: number;
}


const SwapRequestSetting: any = ({
  data,
  isModalVisible,
  setModalVisible,
  handleRefresh,
}) => {
  const { t } = useTranslation();
  const [activeAdd, setActiveAdd] = useState(false);
  const [formValues, setFormValues] = useState({
    allowRequestSwap: false,
    isSequential: false,
    offsetTime: 0,
    dynamicItems: [] as DynamicItem[],
    stateMachine: [] as StateMachineItem[], // Initially empty state machine
  });

  const handleToggleSwitch = (field: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: !prevValues[field], // Toggle boolean field
    }));
  };

  const handleInputChange = (field: string, value: any) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleAddItem = () => {
    if (formValues.dynamicItems.length < 3) {
      const newItem = {
        id: Date.now(),
        role: "user",
        isChecked: false,
      };

      setFormValues((prevValues) => {
        const newStateMachineItem: StateMachineItem = {
          type: newItem.role,
          is_required: newItem.isChecked,
        };

        return {
          ...prevValues,
          dynamicItems: [...prevValues.dynamicItems, newItem],
          stateMachine: [...prevValues.stateMachine, newStateMachineItem]
        };
      });

      setActiveAdd(false);
    } else {
      setActiveAdd(true);
    }
  };

  const handleDynamicItemChange = (index: number, field: string, value: any) => {
    setFormValues((prevValues) => {
      const newItems = [...prevValues.dynamicItems];
      newItems[index] = {
        ...newItems[index],
        [field]: value,
      };

      const newStateMachine = [...prevValues.stateMachine];
      if (field === "role") {
        newStateMachine[index] = {
          ...newStateMachine[index],
          type: value,
        };
      } else if (field === "isChecked") {
        newStateMachine[index] = {
          ...newStateMachine[index],
          is_required: value,
        };
      }

      return {
        ...prevValues,
        dynamicItems: newItems,
        stateMachine: newStateMachine,
      };
    });
  };

  const handleSave = async () => {
    try {
      const response = await fetchWithToken(
        `/swap-request-config/1`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            is_allowed: formValues.allowRequestSwap,
            offset_time_before_sending: formValues.offsetTime,
            is_sequential: formValues.isSequential,
            state_machine: formValues.stateMachine,
          }),
        }
      );
      setModalVisible(false);
      SuccessToast(t("Created"), t("Config Updated Successfully"), t);
    } catch (error) {
      FailToast(t("Accept swap failed. Please try again."),t)
      console.error("Error updating profile", error);
    }
  };

  return (
    isModalVisible && (
      <main
        className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center items-center z-[1]"
        onClick={() => setModalVisible(!isModalVisible)}
      >
        <div
          className="py-2 xl:w-[30%] w-[100%] h-[60%] overflow-auto m-auto bg-white rounded-lg flex flex-col items-center"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="py-3 text-center text-lg font-bold">
            {t("SwapRequest")}
          </div>
          {/* Allow Request Swap Toggle */}
          <div className="flex items-center justify-between w-full p-4">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="hidden"
                checked={formValues.allowRequestSwap}
                onChange={() => handleToggleSwitch("allowRequestSwap")}
              />
              <div className={`w-11 h-6 rounded-full ${formValues.allowRequestSwap ? 'bg-blue-600' : 'bg-gray-200'} relative transition-colors duration-300`}>
                <div className={`absolute left-1 top-1 w-4 h-4 rounded-full bg-white transition-transform duration-300 ${formValues.allowRequestSwap ? 'translate-x-full' : ''}`}></div>
              </div>
              <span className="ml-4 font-medium">
  {t("Allow To Request Swap Request")}
              </span>
            </label>
          </div>

          {/* isSequential Toggle */}
          <div className="flex items-center justify-between w-full p-4">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="hidden"
                checked={formValues.isSequential}
                onChange={() => handleToggleSwitch("isSequential")}
              />
              <div className={`w-11 h-6 rounded-full ${formValues.isSequential ? 'bg-blue-600' : 'bg-gray-200'} relative transition-colors duration-300`}>
                <div className={`absolute left-1 top-1 w-4 h-4 rounded-full bg-white transition-transform duration-300 ${formValues.isSequential ? 'translate-x-full' : ''}`}></div>
              </div>
              <span className="ml-4 font-medium">{t("is_sequential")}</span>
            </label>
          </div>

          {/* Offset Time Input */}
          <div className="flex items-center w-full p-4">
            <span>{t("Offset Time:")}</span>
            <input
              type="number"
              className="border ml-5 h-10 pl-3 rounded-lg"
              value={formValues.offsetTime}
              onChange={(e) => handleInputChange("offsetTime", e.target.value)}
            />
          </div>

          {/* Dynamic Items */}
          <div className="font-medium text-left border-t-2 pt-4 w-full pl-8">
            {t("Add Permissions")}
          </div>
          {formValues.dynamicItems.map((item, index) => (
            <div key={item.id} className="flex justify-start w-full items-center p-4">
              <input
                type="checkbox"
                className="sr-only"
                checked={item.isChecked}
                onChange={() =>
                  handleDynamicItemChange(index, "isChecked", !item.isChecked)
                }
              />
              <div className="relative">
                <div
                  className={`w-11 h-6 bg-gray-200 rounded-full cursor-pointer transition-colors duration-300 ${item.isChecked ? 'bg-blue-600' : ''
                    }`}
                  onClick={() => handleDynamicItemChange(index, "isChecked", !item.isChecked)}
                >
                  <div
                    className={`absolute w-4 h-4 mt-1 bg-white rounded-full shadow transition-transform duration-300 transform ${item.isChecked ? 'translate-x-5' : 'translate-x-1'
                      }`}
                  ></div>
                </div>
              </div>
              <select
                className="ml-4 p-2 border rounded-md"
                value={item.role}
                onChange={(e) =>
                  handleDynamicItemChange(index, "role", e.target.value)
                }
              >
 <option value="user">{t("User2")}</option>
                <option value="manager">{t("Admin")}</option>
                <option value="department">{t("Department")} [{t("secretary")}]
                </option>
              </select>
            </div>
          ))}


          {/* Add More Button */}
          <div className="text-left w-full pl-8 mb-5">
            <button
              disabled={activeAdd}
              className={`mt-2 px-4 py-2 rounded text-white ${activeAdd ? 'bg-gray-400' : 'bg-green-600 hover:bg-green-500'}`}
              onClick={handleAddItem}
            >
{t("+ Add More")}
            </button>
          </div>

          {/* Save and Close Buttons */}
          <div className="m-auto flex">
            <button
              type="button"
              className="w-[174px] h-[40px] border border-green-600 text-green-600 rounded-lg mr-2"
              onClick={() => setModalVisible(false)}
            >
              {t("close")}
            </button>
            <button
              className="w-[174px] h-[40px] bg-green-600 text-white rounded-lg"
              onClick={handleSave}
            >
              {t("save")}
            </button>
          </div>
        </div>
      </main>
    )
  );
};

export default SwapRequestSetting;