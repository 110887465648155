import React from "react";
import arrowIcon from "../assets/Arrows.svg";
import { usePagination } from "./PaginationProvider";

const TableFooter = () => {
  const { currentPage, totalPages, setCurrentPage, count, totalCount, setCount } = usePagination();

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCount(parseInt(e.target.value, 10));
  };

  return (
    <div className="w-full h-[60px]">
      <div className="flex items-center justify-between bg-[#F7F8F7] rounded-lg shadow-md px-6 py-2 w-full h-full">
        <div className="flex items-center">
          {/* <span className="text-[14px] text-[#101010] font-semibold">Rows</span>
          <div className="ml-2 flex items-center border border-gray-300 bg-white rounded px-2">
            <select
              className="text-[#101010] text-[14px] font-semibold bg-white outline-none cursor-pointer"
              value={count}
              onChange={handleCountChange}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
          </div> */}
        </div>

        <div className="text-[14px] font-semibold text-center text-[#101010]">
          Items {Math.min((currentPage - 1) * count + 1, totalCount)} - {totalCount} 
        </div>

        <div className="flex items-center">
          <img onClick={handlePreviousPage} src={arrowIcon} alt="arrow icon" className="w-4 h-4 cursor-pointer" />
          <img
            onClick={handleNextPage}
            src={arrowIcon}
            alt="arrow icon"
            className="rotate-180 w-4 h-4 cursor-pointer"
          />
          <span className="text-[14px] font-semibold mr-2 text-[#101010]">
            Page {currentPage} of {totalPages}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TableFooter;
