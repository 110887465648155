import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../utils/api";

interface UpComingShifts {
  shift: any;
  date: string;
  start_time: string;
  end_time: string;
  shift_type: string;
}

const UpComingShifts = () => {
  const [UpComingShifts, setUpComingShifts] = useState<UpComingShifts[]>([]);
  const { t, i18n } = useTranslation();
  const fetchUpComingShifts = async () => {
    try {
      const data = await fetchWithToken("/schedules/upcoming", {
        method: "GET",
      });
      setUpComingShifts(data?.data);
    } catch (error) {
      console.error (t("Failed to fetch swap requests:"), error);
    }
  };

  useEffect(() => {
    fetchUpComingShifts();
  }, []);

  return (
    <div>
      <div className="text-[24px] font-[700] mb-[16px]">{t("UpcomingShift")}</div>
      {Array.isArray(UpComingShifts) &&  UpComingShifts?.map((item,index) => (
        <div
          className="bg-[#f7f8f7] rounded-[8px] w-full max-h-[250px] flex flex-col justify-between my-2 p-3 py-4"
          key={index}
        >
          <div className="text-[16px] font-[600] text-[#1f7549] capitalize">
            {item?.shift?.shift_type}
            <span className="text-[black] ml-3">
            {moment(item?.shift?.start_time, "HH:mm:ss").format("h:mm A")}
            </span>
          </div>
          <div className="text-[16px] text-[#5d6561]">
            {moment(item?.date).format("ddd, DD MMM YYYY")}
          </div>
        </div>
      ))}
    </div>
  );
};

export default UpComingShifts;
