import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import dashboard from "../../assets/dashboard.png";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import resLogo from "../../assets/logo.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup?.object({
      email: Yup?.string()
        // ?.email("Invalid email address")
        ?.required("This filed is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await fetchWithToken("/auth/forgot-password", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });
        setLoading(false);
        // SuccessToast("Sent", response?.message);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } catch (error: any) {
        setLoading(false);
        // FailToast(error?.message);
      }
    },
  });
  return (
    <main className="flex flex-1 h-[100vh] min-h-[100vh] max-h-[100vh] w-full items-center justify-around bg-[#F7F8F7]">
      <div className="w-[40%] h-full relative flex items-center justify-center">
        <img
          onClick={() => navigate("/")}
          src={logo}
          alt="logo"
          className="w-[75px] absolute top-[35px] left-[20px] md:flex hidden cursor-pointer"
        />
        <form className="w-[310px]" onSubmit={formik?.handleSubmit}>
          <div className="md:hidden mb-7 flex justify-center h-[80px]">
            <img src={resLogo} alt="logo" className="w-[80px] " />
          </div>
          <div className="text-xl font-bold pb-7 capitalize">
{t("Title")}
          </div>
          <div className="font-bold text-sm mb-2 capitalize">{t("Email")}</div>
          <input
            // type="email"
            placeholder={t("EnterEmail")}
            name="email"
            className="w-[280px] h-[35px] text-[#101010] border placeholder-[#5D6561] rounded-lg bg-[#F7F8F7] p-2 text-xs outline-none"
            id="email"
            onChange={(event) => {
              formik.handleChange(event);
            }}
            onBlur={formik?.handleBlur}
            value={formik?.values?.email}
            style={{
              borderColor:
                formik?.touched?.email && formik?.errors?.email
                  ? "#E23121"
                  : "#5D6561",
            }}
          />
          <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
            {formik?.touched?.email && formik?.errors?.email && (
              <div>{formik?.errors?.email}</div>
            )}
          </div>

          <div className="w-[280px]">
          <button
            type="submit"
            className={`w-[280px] rounded-lg bg-[#00A843] hover:bg-[#389365] ${
              loading ? "bg-[#7CCF93] cursor-not-allowed" : ""
            } text-white p-2 text-sm mt-4 flex items-center justify-center`}
            disabled={loading}
          >
            {loading ? (
              <div className="w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin"></div>
            ) : (
              t("Spassword")
            )}
          </button>
            {/* <AnimatedBtn
              txt="Send Password Reset"
              status={status}
              setStatus={setStatus}
            /> */}
          </div>
          <div className="flex mx-[30px] mt-6">
            <div className="text-xs text-[#333937] py-1">
              {t("Rtitle")}
            </div>
            <button
              onClick={() => navigate("/")}
              className="text-base text-[#00A843] font-600 pl-1"
            >
              {t("Rbutton")}
            </button>
          </div>
        </form>
      </div>
      <div className="h-full w-[60%] bg-custom-gradient relative sm:flex hidden">
        <div className="mt-[130px] ml-[15%]">
          <div className="text-[18px] font-[700]">
            {t("Title")}
          </div>
          <div className="text-[18px] font-[500]">
            {t("Title2")}
          </div>
        </div>
        <img
          alt="dashboard"
          src={dashboard}
          className="absolute w-[85%] right-0 top-[280px]"
        />
      </div>
    </main>
  );
};
export default ResetPassword;
