import React, { memo } from "react";
import EveryoneTable from "../../components/everyoneTable";

const Everyone = () => (
  <div className="w-full h-full flex flex-1 overflow-hidden">
    <EveryoneTable />
  </div>
);

export default memo(Everyone);
