import React, { useState } from "react";
import moment from "moment";
import calender from "../../assets/calender.png";
import clock from "../../assets/clock.png";
import warning2 from "../../assets/warning2.png";
import requestBtn from "../../assets/requestBtn.png";
import stethoscope from "../../assets/stethoscope.png";
import location from "../../assets/location.png";
import request from "../../assets/request.png";
import reqMsg from "../../assets/reqMsg.png";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import { useTranslation } from "react-i18next";
import AnimatedBtn from "../animatedBtn";
import fetchWithToken from "../../utils/api";
import AddNoteModal from "./AddNoteModal";
import { useSelector } from "react-redux";

interface SwapReq {
  id: number;
  coworker: string;
  fromdate: string;
  todate: string;
  currentstarttime: string;
  currentendtime: string;
  desiredstarttime: string;
  desiredendtime: string;
  department: number;
  location: number;
  req_message: string;
}

interface SwapRequests {
  id: number;
  schedule_from: string;
  startfrom: string;
  created_datetime: string;
  endfrom: string;
  shifttypefrom: string;
  profile_from: number;
  first_name_from: string;
  last_name_from: string;
  dept_from: string;
  position_from: string;
  code_name_from: string;
  location_from: string;
  schedule_to: string;
  startto: string;
  endto: string;
  shifttypeto: string;
  profile_to: number;
  first_name_to: string;
  last_name_to: string;
  dept_to: string;
  position_to: string;
  code_name_to: string;
  location_to: string;
  req_message: string;
}

interface SwapRequestProps {
  data?: SwapRequests | undefined;
  isModalVisible?: boolean | string | number;
  setModalVisible?: any;
  handleRefresh?: () => void;
  fetchSwapRequests?: any;
}

const SwapRequest: any = ({
  data,
  isModalVisible,
  setModalVisible,
  handleRefresh,
}) => {
  const UserPermissions = useSelector((state: any) => state?.user?.permissions);
  const hasUpdateSwapRequests = Array.isArray(UserPermissions) && UserPermissions.includes("update swap_requests");
  const { t, i18n } = useTranslation();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isDecline, setIsDecline] = useState<boolean>(false);
  const [action, setAction] = useState<boolean>(false);
  const [isAddNoteModalVisible, setAddNoteModalVisible] = useState(false);
  const [selectedSwapRequest, setSelectedSwapRequest] = useState();


  const handleShowNotes = (data: any, action) => {
    setAction(action)
    setAddNoteModalVisible(true);
    setSelectedSwapRequest(data);
  };

  const formatTimeDifference = (createdDateTime: string) => {
    const createdMoment = moment(createdDateTime);
    const now = moment();
    //return now.diff(createdMoment, "minutes") + " " + t("min ago")
    const totalMinutes = now.diff(createdMoment, "minutes");
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const days = Math.floor(hours / 24);

    if (days === 1) {
      return t("days_ago_arabic_one", { count: days }); // For 1 day
    } else if (days === 2) {
      return t("days_ago_arabic_two", { count: days }); // For 2 days
    } else if (days >= 3 && days <= 10) {
      return t("days_ago_arabic_few", { count: days }); // For 3 to 10 days
    } else if (days >= 11) {
      return t("days_ago_arabic_many", { count: days }); // For 11 and above
    } else if (hours >= 1) {
      return t("hours_minutes_ago", { hours, minutes });
    } else if (minutes > 0) {
      return t("minutes_ago", { minutes });
    } else {
      return t("now");
    }
  };


  return (
    isModalVisible && (
      <main
        className="fixed top-0 bottom-0 left-0 right-0
         bg-black bg-opacity-30 flex justify-center align-middle z-[1]"
        onClick={() => setModalVisible(!isModalVisible)}
      >
        <AddNoteModal
          approved={action}
          data={selectedSwapRequest}
          isModalVisible={isAddNoteModalVisible}
          setModalVisible={setAddNoteModalVisible}
          handleRefresh={handleRefresh}
        />
        <div
          className={`py-2 xl:w-[30%] w-[100%] "h-[67%]" 
            overflow-auto m-auto capitalize bg-[#FFF] 
            rounded-[8px] flex flex-col items-center scrollbar-hidden`}
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="py-3 text-center text-lg font-bold">{t("SwapRequest")}</div>
          <div className="text-sm text-[#101010] w-full">
            <div className="px-3 pt-2 flex pb-3 items-center">
              <img src={warning2} alt="!" className="w-6 h-6 p-1" />
              <div className="text-[#101010] font-bold text-lg flex-1">
                {data?.from_user?.first_name} {data?.from_user?.last_name}
                <span className="text-[#5D6561] text-sm ml-2 lowercase">
                  {t("RequestASwap")}
                </span>
              </div>
              <span className="text-[#5D6561] text-sm font-semibold ml-2 lowercase">
                {data?.createdAt
                  ? formatTimeDifference(data.createdAt!)
                  : t("Unknown Time")}
              </span>
            </div>


            <div className="px-1 flex justify-between mb-2 mx-[14px]">
              <div className="w-[170px] flex flex-col">
                <div className="text-[12px] text-[#A4ACA7]">{t("From")}</div>
                <div className="text-[12px] flex items-center">
                  <img alt="calender" src={calender} className="w-[13px]" />
                  <div className="text-[16px] ml-3">
                  {moment(data?.from_schedule?.date).isValid()
  ? moment(data?.from_schedule?.date).format("ddd, DD MMM YYYY")
  : t("Invalid date")}
                  </div>
                </div>
              </div>
              <img src={requestBtn} alt="" className="w-5 h-5 mt-8 mr-4" />
              <div className="w-[170px] flex flex-col">
                <div className="text-[12px] text-[#A4ACA7]">{t("To")}</div>
                <div className="text-[12px] flex items-center">
                  <img alt="calender" src={calender} className="w-[13px]" />
                  <div className="text-[16px] ml-3">
                  {moment(data?.to_schedule?.date).isValid()
  ? moment(data?.to_schedule?.date).format("ddd, DD MMM YYYY")
  : t("Invalid date")}                  </div>
                </div>
              </div>
            </div>
            <div className="px-1 flex justify-between mx-[14px]">
              <div className="w-[170px] flex flex-col">
                <div className="text-[12px] flex items-center">
                  <img alt="clock" src={clock} className="w-[13px]" />
                  <div className="text-[16px] ml-3">
                  {moment(data?.from_schedule?.shift?.start_time, "HH:mm:ss").isValid()
  ? moment(data?.from_schedule?.shift?.start_time, "HH:mm:ss").format("h:mm A")
  : t("Invalid date")} -{" "}                   
  
  {moment(data?.from_schedule?.shift?.end_time, "HH:mm:ss").isValid()
  ? moment(data?.from_schedule?.shift?.end_time, "HH:mm:ss").format("h:mm A")
  : t("Invalid date")}{" "}                  </div>
                </div>
              </div>
              <div className="w-[170px] flex flex-col">
                <div className="text-[12px] flex items-center">
                  <img alt="clock" src={clock} className="w-[13px]" />
                  <div className="text-[16px] ml-3">
                  {moment(data?.to_schedule?.shift?.start_time, "HH:mm:ss").isValid()
  ? moment(data?.to_schedule?.shift?.start_time, "HH:mm:ss").format("h:mm A")
  : t("Invalid date")} -{" "}                   
  
  {moment(data?.to_schedule?.shift?.end_time, "HH:mm:ss").isValid()
  ? moment(data?.to_schedule?.shift?.end_time, "HH:mm:ss").format("h:mm A")
  : t("Invalid date")}{" "}                  </div>
                </div>
              </div>
            </div>


            <div className="px-1 m-3 mx-[14px]">
              <div className="flex items-center mb-2">
                <img src={stethoscope} alt="" className="w-[13px] h-[13px]" />
                <div className="text-base text-[#333937] ml-3">
                  {data?.dept_to || t("No Department")}
                </div>
              </div>
              <div className="flex items-center mb-2">
                <img src={location} alt="" className="w-[13px] h-[13px]" />
                <div className="text-base text-[#333937] ml-3">
                  {data?.location_to || t("No Location")}
                </div>
              </div>
              <div className="flex items-center">
                <img src={request} alt="" className="w-[13px] h-[13px]" />
                <div className="text-base text-[#333937] ml-3">
                  {data?.message || t("No Message")}
                </div>
              </div>
            </div>

          </div>
          {
            hasUpdateSwapRequests && (
              <div className="m-auto flex">
                <button
                  type="button"
                  // onClick={handleDecline}
                  onClick={() => handleShowNotes(data, false)}
                  className={`w-[174px] h-[40px] rounded-[8px] border border-[#00a843] text-[#00a843] hover:border-[#1F7549] hover:text-[#1F7549] ${isDecline ? "text-[#56B77B] border-[#56B77B]" : ""
                    } text-[16px] font-[700] px-[14px] py-[8px] mr-2`}
                >
                  {t("DeclineSwap")}
                </button>
                <div className="w-[174px] mr-3">
                  {/* <AnimatedBtn onClick={handleAccept} txt= {t("AcceptSwap")} status={status} setStatus={setStatus} /> */}
                  <button
                    // onClick={handleAccept}
                    onClick={() => handleShowNotes(data, true)}
                    className={`w-[174px] h-[40px] rounded-[8px] bg-[#00A843] hover:bg-[#389365] text-[#F8FAF8] ${isSubmit ? "bg-[#7CCF93]" : ""
                      } text-[16px] px-[24px] py-[8px] mr-2`}
                  >
                    {t("AcceptSwap")}
                  </button>
                </div>

              </div>
            )
          }

        </div>
      </main>
    )
  );
};

export default SwapRequest;