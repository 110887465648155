import Cookies from "js-cookie";
import { useEffect, useState } from "react";

export const setToken = (token: string) => {
  Cookies.set("session", token, {
    expires: new Date(new Date().getTime() + 1 * 60 * 60 * 500), // Expires in 30 minutes
    secure: true,
    sameSite: "strict",
  });
};

export const getToken = () => {
  return Cookies.get("session");
};

export const removeToken = () => {
  Cookies.remove("session");
};

export const updateUserProfile = (updatedUserInfo: any) => {
  const updatedUser = updatedUserInfo;

  Cookies.set("user", JSON?.stringify(updatedUser), {
    expires: new Date(new Date().getTime() + 1 * 60 * 60 * 500), // Expires in 30 minutes
  });

  window.dispatchEvent(new CustomEvent("userUpdated", { detail: updatedUser }));
};

export const useUser = () => {
  const [user, setUser] = useState(() => Cookies.get("user"));

  useEffect(() => {
    const handleUserUpdate = (event: any) => {
      setUser(event.detail);
    };

    // Listen for the custom 'userUpdated' event
    window.addEventListener("userUpdated", handleUserUpdate);

    return () => {
      window.removeEventListener("userUpdated", handleUserUpdate);
    };
  }, []);

  return user;
};
