import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-dropdown/style.css";
import Select, { SingleValue } from "react-select";
import moment from "moment";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import SuccessToast from "./successToast";

const ScheduleModal: any = ({
  isModalVisible,
  setModalVisible,
  fetchSchedules,
}) => {
  const isAdd = isModalVisible === true;
  const { t, i18n } = useTranslation();
  const [profiles, setProfiles] = useState<any>([]);
  const [shifs, setShifts] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [IsMultiDays, setIsMultiDays] = useState(false);
  const [isRotation, setIsRotation] = useState(false);
  const [multiDays, setMultiDays] = useState<{ value: string; name: string }[]>(
    []
  );

  const formik = useFormik<{
    date: string;
    profileId: SingleValue<{
      value: number;
      label: string;
      _id: string;
    }> | null;
    shiftId: SingleValue<{ value: number; label: string; _id: string }> | null;
    deptId: SingleValue<{ value: number; label: string; _id: string }> | null;
  }>({
    initialValues: {
      date: "",
      profileId: null,
      shiftId: null,
      deptId: null,
    },
    validationSchema: Yup.object({
      date: Yup.date()
        .typeError(t("Invalid date format")),
      profileId: Yup.object().required(t("Profile is required")),
      shiftId: Yup.object().required(t("Shift is required")),
      deptId: Yup.object().required(t("Department is required")),
    }),
    onSubmit: async (values) => {
      try {
        if (IsMultiDays) {
          const numericDays = multiDays.map((day) => Number(day.value));
          await fetchWithToken("/schedules-rules", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              daysOfWeek: numericDays,
              user_id: values?.profileId?._id,
              shift_id: values?.shiftId?._id,
              // dept_id: values?.deptId?.value,//hota hon
            }),
          });
          SuccessToast(t("Created"), t("Schedule created successfully"), t);
        } else {
          await fetchWithToken(
            isAdd ? "/schedules" : `/schedules/${isModalVisible}`,
            {
              method: isAdd ? "POST" : "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                date: values?.date,
                user_id: values?.profileId?._id,
                shift_id: values?.shiftId?._id,
                // dept_id: values?.deptId?.value,
              }),
            }
          );
          SuccessToast(t("Created"), t("Schedule created successfully"), t);
        }
        setModalVisible(!isModalVisible);
        fetchSchedules();
      } catch (error) {
        console.error(t("Error creating schedule:"), error);
      }
    },
  });

  const getSceduleDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/schedules/${id}`, {
        method: "GET",
      });
      formik?.setFieldValue(
        "date",
        moment(data?.data?.date).format("YYYY-MM-DD")
      );
      formik?.setFieldValue(
        "profileId",
        profiles?.filter((each) => each?._id === data?.data?.user?._id)[0]
      );
      formik?.setFieldValue(
        "shiftId",
        shifs?.filter((each) => each?._id === data?.data?.shift?._id)[0]
      );
      formik?.setFieldValue(
        "deptId",
        departments?.filter((each) => each?._id === data?.data?.user?.department?._id)[0]
      );

    } catch (error) {
      //console.error("Failed to fetch schedule:", error);
      console.error(t("Failed to fetch schedule:"), error);
    }
  };

  const fetchProfiles = async () => {
    try {
      const data = await fetchWithToken("/users", {
        method: "GET",
      });

      setProfiles(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?._id,
          label: each?.email,
        }))
      );
    } catch (error) {
      console.error(t("Failed to fetch profiles:"), error);
    }
  };

  const fetchShifts = async () => {
    try {
      const data = await fetchWithToken("/shifts", {
        method: "GET",
      });

      setShifts(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?._id,
          label: each?.start_time,
        }))
      );
    } catch (error) {
      console.error(t("Failed to fetch shifts:"), error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const data = await fetchWithToken("/departments", {
        method: "GET",
      });

      setDepartments(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?._id,
          label: each?.name,
        }))
      );
    } catch (error) {
      console.error(t("Failed to fetch departments:"), error);
    }
  };

  useEffect(() => {
    formik?.resetForm();
    if (
      typeof isModalVisible === "number" ||
      typeof isModalVisible === "string"
    )
      getSceduleDetails(isModalVisible);
  }, [isModalVisible]);

  useEffect(() => {
    fetchProfiles();
    fetchShifts();
    fetchDepartments();
  }, []);

  const daysOfWeek = [
    { value: "0", label: t("Sat") },
    { value: "1", label: t("Sun") },
    { value: "2", label: t("Mon") },
    { value: "3", label: t("Tue") },
    { value: "4", label: t("Wed") },
    { value: "5", label: t("Thu") },
    { value: "6", label: t("Fri") },
  ];

  const toggleDay = (day: { value: string; name: string }) => {
    setMultiDays((prev) => {
      const exists = prev.find((d) => d.value === day.value);
      const newDays = exists
        ? prev.filter((d) => d.value !== day.value)
        : [...prev, day];

      return newDays;
    });
  };
  useEffect(() => {
    setIsMultiDays(false);
  }, [isAdd]);

  return (
    isModalVisible && (
      <main
        onClick={() => setModalVisible(!isModalVisible)}
        className="fixed top-0 bottom-0 left-0 right-0 
        bg-black bg-opacity-30 flex justify-center items-center"
      >
        <div className="container my-auto">
          <div
            onClick={(e) => e?.stopPropagation()}
            className="py-5 max-w-[90%] h-auto sm:max-w-[65%] sm:h-[70%] 
              overflow-auto m-auto w-full sm:w-[405px] capitalize 
              bg-[#FFF] rounded-[8px] flex flex-col items-center scrollbar-hidden"
          >
            <div className="text-center text-lg font-bold">
              {isAdd ? t("AddSchedule") : t("EditSchedule")}
            </div>
            {isAdd && (
              <>
                <div className="flex flex-row justify-start sm:ml-[15px]  w-full gap-[30px] my-[12px]">
                  <div
                    className="flex flex-row gap-1 items-center"
                    onClick={() => setIsMultiDays(false)}
                  >
                    <label className="text-[16px]">
                      {t("Single Schedule")}
                    </label>
                    <input
                      type="checkbox"
                      className={"w-[20px] h-[20px]"}
                      checked={IsMultiDays == false}
                    />
                  </div>
                  <div
                    className="flex flex-row gap-1 items-center"
                    onClick={() => setIsMultiDays(true)}
                  >
                    <label className="text-[16px]">{t("repeated")}</label>
                    <input
                      type="checkbox"
                      className={"w-[20px] h-[20px]"}
                      checked={IsMultiDays == true}
                    />
                  </div>
                  <div
                    className="flex flex-row gap-1 items-center"
                    onClick={() => setIsRotation((prev) => !prev)}
                  >
                    <label className="text-[16px]">{t("rotation")}</label>
                    <input
                      type="checkbox"
                      className={"w-[20px] h-[20px]"}
                      checked={isRotation == true}
                    />
                  </div>
                </div>
                {IsMultiDays && (
                  <>
                    <div className="font-bold mb-2">{t("Select Days")}</div>
                    <div className="flex space-x-2 mb-6">
                      {daysOfWeek.map((day) => (
                        <div key={day.value} className="relative">
                          <div
                            onClick={() =>
                              toggleDay({ value: day.value, name: day.label })
                            }
                            className={`w-7 h-7 flex justify-center items-center rounded-full border cursor-pointer 
      ${multiDays.some((d) => d.value === day.value) // Ensure we're checking value correctly
                                ? "bg-blue-500 text-white"
                                : "bg-gray-200"
                              }`}
                            title={day.label}
                          >
                            {day.label.charAt(0)}
                          </div>
                          <span
                            className="absolute bottom-14 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 opacity-0 transition-opacity duration-200 group-hover:opacity-100"
                            style={{
                              visibility: multiDays.some(
                                (d) =>
                                  d.value === day.label.charAt(0).toLowerCase()
                              )
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {day.label}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}

            <div className="text-sm text-[#101010]">
              {!IsMultiDays && (
                <>
                  <div className="font-bold">{t("Date")}</div>
                  <input
                    type="date"
                    placeholder={t("Select date")}
                    name="date"
                    required
                    className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
                    id="date"
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.date}
                    style={{
                      borderColor:
                        formik?.touched?.date && formik?.errors?.date
                          ? "#E23121"
                          : "#5D6561",
                    }}
                  />
                  <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
                    {formik?.touched?.date && formik?.errors?.date && (
                      <div>{formik?.errors?.date}</div>
                    )}
                  </div>
                </>
              )}

              <div className="font-bold">{t("Profile")}</div>
              <Select
                placeholder={t("Select")}
                options={profiles}
                value={formik.values.profileId || t("Select profile")}
                name="profileId"
                onChange={(option) => formik.setFieldValue("profileId", option)}
                onBlur={formik.handleBlur}
                className="w-[350px] h-[40px] my-2"
              />
              <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
                {formik?.touched?.profileId && formik?.errors?.profileId && (
                  <div>{formik?.errors?.profileId}</div>
                )}
              </div>
              <div className="font-bold">{t("Shift")}</div>
              <Select
                placeholder={t("Select")}
                options={shifs}
                value={formik.values.shiftId || t("Select shift")}
                name="shiftId"
                onChange={(option) => formik.setFieldValue("shiftId", option)}
                onBlur={formik.handleBlur}
                className="w-[350px] h-[40px] my-2"
              />
              <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
                {formik?.touched?.shiftId && formik?.errors?.shiftId && (
                  <div>{formik?.errors?.shiftId}</div>
                )}
              </div>
              <div className="font-bold">{t("Department")}</div>
              <Select
                placeholder={t("Select")}
                options={departments}
                value={formik.values.deptId || t("Select departement")}
                name="deptId"
                onChange={(option) => formik.setFieldValue("deptId", option)}
                onBlur={formik.handleBlur}
                className="w-[350px] h-[40px] my-2"
              />
              <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
                {formik?.touched?.deptId && formik?.errors?.deptId && (
                  <div>{formik?.errors?.deptId}</div>
                )}
              </div>
            </div>
            <div className="w-[350px]">
              <button
                type="button"
                onClick={() => setModalVisible(!isModalVisible)}
                className="w-[168px] h-[40px] rounded-[8px] border border-[#00a843] text-[#00a843] hover:border-[#E23121] hover:text-[#E23121] text-[16px] font-[700] px-[24px] py-[8px]"
              >
                {t("Cancel")}
              </button>
              <button
                type="submit"
                onClick={(e: any) => {
                  formik.handleSubmit();
                }}
                className="w-[168px] rounded-[8px] bg-[#56b77b] hover:bg-[#00A843] text-[#F8FAF8] p-2 text-[16px] mt-5 px-[24px] py-[8px] ml-[13px]"
              >
                {t("Confirm")}
              </button>
            </div>
          </div>
        </div>
      </main>
    )
  );
};

export default ScheduleModal;
