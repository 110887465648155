import React, { useState } from "react";
import close from "../../assets/close.png"; // Close button image
import { ToastOptions, toast } from "react-toastify"; // Toastify for notifications
import deleteToast from "../../assets/deleteToast.png"; // Custom delete icon for toast
import trash from "../../assets/trash.svg"; // Trash icon for the delete modal
import { useTranslation } from 'react-i18next'; // For multi-language support
import fetchWithToken from "../../utils/api"; // Utility to make authenticated API requests

// Function to show toast notification when item is deleted
const showToast = (id: string | number, t: any) => {
  const toastProps: ToastOptions = {
    position: "bottom-right",
    className: "foo-bar",
    icon: (
      <img
        alt="deleteToast"
        src={deleteToast} // Custom icon for the toast
        className="w-[40px] min-w-[40px] max-w-[40px]"
      />
    ),
  };

  toast.error(
    <div className="msg-container ml-5">
      <p className="msg-title font-[700] text-[18px] text-[black]">
        {t("Deleted successfully")} {/* Translated success message */}
      </p>
      <p className="msg-description text-[12px] text-[#5d6561]">
        {t("Request id")} {id} {t("was deleted")} {/* Display item ID */}
      </p>
    </div>,
    toastProps
  );
};

interface DeleteModalProps {
  route: string; // API endpoint for deletion
  visibilityState: string | number | boolean; // State that controls modal visibility
  setState: React.Dispatch<React.SetStateAction<string | number | boolean>>; // Function to hide the modal
  fetchAllCall: () => void; // Callback to refresh data after deletion
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  route,
  visibilityState,
  setState,
  fetchAllCall,
}) => {
  const [isDecline, setIsDecline] = useState<boolean>(false); // Tracks cancel button state
  const { t } = useTranslation(); // Translation hook

  // Function to handle data deletion
  const deleteData = async () => {
    setIsDecline(true); // Mark as declined when action is initiated
    await fetchWithToken(`/${route}/${visibilityState}`, { // Perform the DELETE request
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    fetchAllCall(); // Refresh data
    setState(false); // Close the modal
    showToast(visibilityState as string | number, t); // Show the success toast
  };

  return (
    visibilityState ? ( // Render the modal only if visibilityState is truthy
      <main
        onClick={() => setState(false)} // Close the modal if clicked outside
        className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center items-center z-[1]"
      >
        <div className="container my-auto px-4">
          <div className="max-w-[90%] sm:max-w-[400px] m-auto w-full bg-white p-4 sm:p-6 rounded-lg shadow-md">
            <div className="flex justify-end">
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => setState(false)} // Close modal on cancel
              >
                <img src={close} alt="close" className="w-[20px] h-[20px]" />
              </button>
            </div>

            <div className="text-center">
              <img
                src={trash} // Trash icon for the delete modal
                alt="delete"
                className="w-[60px] h-[60px] mx-auto my-4"
              />
              <div className="text-[#101010] text-lg font-extrabold mb-4">
                {`${t("Deletemessage")} ${visibilityState}?`} {/* Display delete message */}
              </div>
            </div>

            <div className="flex justify-center space-x-2 mt-4">
              {/* Cancel button */}
              <button
                type="button"
                onClick={() => {
                  setState(false); // Close the modal
                  setIsDecline(true); // Mark as declined
                }}
                className={`w-[120px] h-[40px] rounded-md border border-[#00A843] text-[#389365] hover:border-[#1F7549] hover:text-[#1F7549] ${
                  isDecline ? "text-[#389365] border-[#00A843]" : ""
                } text-[16px] font-semibold`}
              >
                {t("Cancel")}
              </button>

              {/* Delete button */}
              <button
                type="button"
                onClick={deleteData} // Trigger the delete function
                className={`w-[120px] h-[40px] rounded-md border border-[#FF0000] text-[#FF0000] hover:border-[#FF0000] hover:text-[#FF0000] ${
                  isDecline ? "text-[#FF0000] border-[#FF0000]" : ""
                } text-[16px] font-semibold`}
              >
                {t("Delete")}
              </button>
            </div>
          </div>
        </div>
      </main>
    ) : null // If visibilityState is false, do not render the modal
  );
};

export default DeleteModal;
