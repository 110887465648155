import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { Select } from "@headlessui/react";

interface LeaveProps {
  isModalVisible: any;
  setIsModalVisible: any;
  fetchLeaves: any
}

const Leave: React.FC<LeaveProps> = ({ isModalVisible, setIsModalVisible, fetchLeaves }) => {
  const isAdd = isModalVisible === true;
  const { t, i18n } = useTranslation(); 
  const options = [
    {
      id: 1,
      title:t('annual'),
      value:"annual"
    },
    {
      id: 2,
      title: t('sick_leave'),
      value: "sick_leave"
    },
    {
      id: 3,
      title: t('emergency'),
      value: "emergency"
    },
  ];
  const formik = useFormik({
    initialValues: {
      start_date: "",
      end_date: "",
      leave_type: options[0]?.value,
    },

    validationSchema: Yup?.object({
      start_date: Yup?.string()?.required(t("Required")),
      end_date: Yup?.string()?.required(t("Required")),
      leave_type: Yup?.string()?.required(t("Required")),
    }),

    onSubmit: async (values) => {
      try {
        await fetchWithToken(
          isAdd ? `/leaves` : `/leaves/${isModalVisible}`, {
          method: isAdd ? "POST" : "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            start_date: moment(values?.start_date).format("YYYY-MM-DD"),
            end_date: moment(values?.end_date).format("YYYY-MM-DD"),
            type: values?.leave_type,
          }),
        });
        fetchLeaves();
        setIsModalVisible(false);
      } catch (error) {
        //console.error("Error creating leave:", error);
        setIsModalVisible(false);
        console.error(t("Error creating leave:"), error);
      }
    },
  });

  const getLeaveDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/leaves/${id}`, {
        method: "GET",
      });
      
     
      formik?.setFieldValue("start_date",  moment(data.data?.start_date).format("YYYY-MM-DD"));
      formik?.setFieldValue(
        "end_date", moment(data.data?.end_date).format("YYYY-MM-DD")
      );
      formik?.setFieldValue("leave_type", data.data?.type);
    } catch (error) {
      console.error("Failed to fetch department:", error);
    }
  };

  useEffect(() => {
    formik?.resetForm();
    if (
      typeof isModalVisible === "number" ||
      typeof isModalVisible === "string"
    )
      getLeaveDetails(isModalVisible);
  }, [isModalVisible]);

 
  return (
    isModalVisible && (
      <main
        className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center align-middle z-[999]"
        onClick={() => setIsModalVisible(!isModalVisible)}
      >
        <div
          className="py-5  overflow-auto
           m-auto  capitalize bg-[#FFF] rounded-[8px] flex flex-col items-center justify-center  scrollbar-hidden"
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="text-center text-lg font-bold">{t("Mesaage")}</div>
          <div className="text-sm text-[#101010] w-full px-5">
            <div className="font-bold">{t("StartDate")}</div>
            <input
              type="date"
              placeholder={t("StartDateTitle")}
              name="start_date"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="start_date"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.start_date}
              style={{
                borderColor:
                  formik?.touched?.start_date && formik?.errors?.start_date
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.start_date && formik?.errors?.start_date && (
                <div>{formik?.errors?.start_date}</div>
              )}
            </div>
            <div className="font-bold">{t("EndDate")}</div>
            <input
              type="date"
              placeholder={t("EndDateTitle")}
              name="end_date"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="end_date"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.end_date}
              style={{
                borderColor:
                  formik?.touched?.end_date && formik?.errors?.end_date
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.end_date && formik?.errors?.end_date && (
                <div>{formik?.errors?.end_date}</div>
              )}
            </div>
            <div className="font-bold">{t("LeaveType")}</div>
            <Select
              className="w-[350px] h-[40px] my-2 border border-[#7E8581] rounded-md mr-3"
              name="leave_type"
              onBlur={formik?.handleBlur}
              value={formik?.values?.leave_type}
              onChange={formik?.handleChange}
            >
              {
                options.map((item) => (
                  <option key={item?.id} value={item?.value}>{item?.title}</option>
                ))
              }
            </Select>
            
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.leave_type && formik?.errors?.leave_type && (
                <div>{formik?.errors?.leave_type}</div>
              )}
            </div>
          </div>
          <div className="w-[350px]">
            <button
              type="button"
              onClick={() => setIsModalVisible(false)}
              className="w-[168px] h-[40px] rounded-[8px] border border-[#00a843] text-[#00a843] hover:border-[#E23121] hover:text-[#E23121] text-[16px] font-[700] px-[24px] py-[8px]"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={(e: any) => {
                formik.handleSubmit();
              }}
              type="submit"
              className="w-[168px] rounded-[8px] bg-[#56b77b] hover:bg-[#00A843] text-[#F8FAF8] p-2 text-[16px] mt-5 px-[24px] py-[8px] ml-[13px]"
            >
              {t("Submit")}
            </button>
          </div>
        </div>
      </main>
    )
  );
};

export default Leave;
