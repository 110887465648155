import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './utils/redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dropdown/style.css';
import Login from './pages/login/page';
import Dashboard from './pages/dashboard/page';
import Everyone from './pages/everyone/page';
import Cruds from './pages/cruds/page';
import Swap from './pages/swap/page';
import Layout from './components/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import ResetPassword from './pages/resetPassword/page';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Layout>
                  <Dashboard />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/everyone"
            element={
              <ProtectedRoute>
                <Layout>
                  <Everyone />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/cruds"
            element={
              <ProtectedRoute>
                <Layout>
                  <Cruds />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/swap"
            element={
              <ProtectedRoute>
                <Layout>
                  <Swap />
                </Layout>
              </ProtectedRoute>
            }
          />
        </Routes>
        <ToastContainer />
      </Router>
    </Provider>
  );
}

export default App;
