import React, { createContext, useContext, useState } from "react";

interface PaginationContextProps {
  currentPage: number;
  totalPages: number;
  count: number;
  totalCount: number;
  setCurrentPage: (page: number) => void;
  setTotalPages: (pages: number) => void;
  setCount: (count: number) => void;
  setTotalCount: (count: number) => void;
}

const PaginationContext = createContext<PaginationContextProps | undefined>(
  undefined
);

export const PaginationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [count, setCount] = useState<number>(10); // Default to 10 rows per page
  const [totalCount, setTotalCount] = useState<number>(0); // Total items

  return (
    <PaginationContext.Provider
      value={{
        currentPage,
        totalPages,
        count,
        totalCount,
        setCurrentPage,
        setTotalPages,
        setCount,
        setTotalCount,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = () => {
  const context = useContext(PaginationContext);
  if (!context) {
    throw new Error("usePagination must be used within a PaginationProvider");
  }
  return context;
};
