import Cookies from "js-cookie";
import FailToast from "../components/modals/failToast";

async function fetchWithToken(endpoint: string, options: RequestInit = {}) {
  const token = Cookies.get("session");
  const headers = new Headers(options.headers || {});

  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }

  const response = await fetch(
    // `${"/api"}${endpoint}`,
    `${process.env.REACT_APP_API_URL}${endpoint}`,
    {
      ...options,
      headers,
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    // FailToast(errorData?.message)
    // errorData?.errors?.map((each: any) => FailToast(each?.msg));
    // if (errorData?.errors?.length)
    throw new Error(errorData?.message);
  }

  if (response.status === 204) {
    return null;
  }

  return response.json();
}

export default fetchWithToken;
